import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { fetchAgents, addAgent, updateAgent, deleteAgent } from '../services/agentApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTrash, faCheckCircle, faTimesCircle, faChevronLeft, faChevronRight, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { format, parseISO } from 'date-fns';

function DaftarAgent() {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [showModal, setShowModal] = useState(false);
  const [newAgent, setNewAgent] = useState({
    code_agent: '',
    group_agent: '',
    name: '',
    link: '',
    status: 'active',
    tgl_join: '',
    tanggal_closed: ''
  });
  const [editingAgent, setEditingAgent] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const itemsPerPage = 10;
  const maxPagesPerSet = 10;

  useEffect(() => {
    fetchAgents()
      .then((data) => {
        const formattedData = data.map(agent => ({
          ...agent,
          tgl_join: agent.tgl_join ? format(parseISO(agent.tgl_join), 'yyyy-MM-dd') : '',
          tanggal_closed: agent.tanggal_closed ? format(parseISO(agent.tanggal_closed), 'yyyy-MM-dd') : ''
        }));
        setData(formattedData);
        setError(null);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const filteredData = data.filter((item) => {
    const nameMatch = item.name ? item.name.toLowerCase().includes(searchTerm.toLowerCase()) : false;
    const groupMatch = item.group_agent ? item.group_agent.toLowerCase().includes(searchTerm.toLowerCase()) : false;
    const codeMatch = item.code_agent ? item.code_agent.toLowerCase().includes(searchTerm.toLowerCase()) : false;
    const statusMatch = statusFilter === 'all' || item.status === statusFilter;
    return (nameMatch || groupMatch || codeMatch) && statusMatch;
  });

  const pageCount = Math.ceil(filteredData.length / itemsPerPage);

  const getCurrentPageData = () => {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return filteredData.slice(begin, end);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < pageCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const getPaginationButtons = () => {
    const currentPageSet = Math.floor((currentPage - 1) / maxPagesPerSet);
    const startPage = currentPageSet * maxPagesPerSet + 1;
    const endPage = Math.min(startPage + maxPagesPerSet - 1, pageCount);

    const buttons = [];
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <Button
          key={i}
          variant={i === currentPage ? "primary" : "light"}
          onClick={() => handlePageChange(i)}
          className="mr-1"
        >
          {i}
        </Button>
      );
    }

    return buttons;
  };

  const handleShowModal = () => {
    setEditingAgent(null);
    setNewAgent({
      code_agent: '',
      group_agent: '',
      name: '',
      link: '',
      status: 'active',
      tgl_join: '',
      tanggal_closed: ''
    });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setError(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedValue = name === 'tgl_join' || name === 'tanggal_closed' 
      ? value 
      : value;

    if (editingAgent) {
      setEditingAgent(prev => ({
        ...prev,
        [name]: updatedValue,
        status: name === 'tanggal_closed' && value ? 'non active' : prev.status
      }));
    } else {
      setNewAgent(prev => ({
        ...prev,
        [name]: updatedValue,
        status: name === 'tanggal_closed' && value ? 'non active' : prev.status
      }));
    }
  };

  const handleAddAgent = async () => {
    try {
      const agentToAdd = {
        ...newAgent,
        status: newAgent.tanggal_closed ? 'non active' : newAgent.status,
        tgl_join: newAgent.tgl_join ? format(new Date(newAgent.tgl_join), 'yyyy-MM-dd') : null,
        tanggal_closed: newAgent.tanggal_closed ? format(new Date(newAgent.tanggal_closed), 'yyyy-MM-dd') : null
      };
      const addedAgent = await addAgent(agentToAdd);
      setData([...data, {
        ...addedAgent,
        tgl_join: addedAgent.tgl_join ? format(parseISO(addedAgent.tgl_join), 'yyyy-MM-dd') : '',
        tanggal_closed: addedAgent.tanggal_closed ? format(parseISO(addedAgent.tanggal_closed), 'yyyy-MM-dd') : ''
      }]);
      setNewAgent({
        code_agent: '',
        group_agent: '',
        name: '',
        link: '',
        status: 'active',
        tgl_join: '',
        tanggal_closed: ''
      });
      setShowModal(false);
      Swal.fire({
        title: 'Success',
        text: 'Agent successfully added.',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  const handleUpdateAgent = async () => {
    try {
      const agentToUpdate = {
        ...editingAgent,
        status: editingAgent.tanggal_closed ? 'non active' : editingAgent.status,
        tgl_join: editingAgent.tgl_join ? format(new Date(editingAgent.tgl_join), 'yyyy-MM-dd') : null,
        tanggal_closed: editingAgent.tanggal_closed ? format(new Date(editingAgent.tanggal_closed), 'yyyy-MM-dd') : null
      };
      await updateAgent(editingAgent.id, agentToUpdate);
      setData((prevData) =>
        prevData.map((agent) => (agent.id === editingAgent.id ? {
          ...agent,
          ...agentToUpdate,
          tgl_join: agentToUpdate.tgl_join ? format(parseISO(agentToUpdate.tgl_join), 'yyyy-MM-dd') : '',
          tanggal_closed: agentToUpdate.tanggal_closed ? format(parseISO(agentToUpdate.tanggal_closed), 'yyyy-MM-dd') : ''
        } : agent))
      );
      setShowModal(false);
      setEditingAgent(null);
      Swal.fire({
        title: 'Success',
        text: 'Agent successfully updated.',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  const handleDeleteAgent = async (id) => {
    const result = await Swal.fire({
      title: 'Confirmation',
      text: 'Are you sure you want to delete this agent?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    });

    if (result.isConfirmed) {
      try {
        await deleteAgent(id);
        setData(data.filter(agent => agent.id !== id));
        if (getCurrentPageData().length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
        Swal.fire({
          title: 'Success',
          text: 'Agent successfully deleted.',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: error.message,
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    }
  };

  const handleEdit = (agent) => {
    setEditingAgent(agent);
    setShowModal(true);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleStatusFilter = (e) => {
    setStatusFilter(e.target.value);
    setCurrentPage(1);
  };

  const openLinkInNewTab = (url) => {
    window.open(url, '_blank');
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <div className="card shadow mb-4">
        <div className="card-header py-3 d-flex justify-content-between align-items-center">
          <h6 className="m-0 font-weight-bold text-primary">Daftar Agent</h6>
          <div className="d-flex">
            <input 
              type="text" 
              placeholder="Search..." 
              value={searchTerm}
              onChange={handleSearch}
              className="form-control mr-2"
            />
            <select 
              value={statusFilter} 
              onChange={handleStatusFilter}
              className="form-control mr-2"
            >
              <option value="all">All Status</option>
              <option value="active">active</option>
              <option value="non active">non active</option>
            </select>
            <Button variant="primary" onClick={handleShowModal}>
              Tambah
            </Button>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
              <thead>
                <tr>
                  <th style={{ width: '5%' }}>No</th>
                  <th style={{ width: '10%' }}>Kode Agent</th>
                  <th style={{ width: '10%' }}>Nama Website</th>
                  <th style={{ width: '10%' }}>Group Agent</th>
                  <th style={{ width: '20%' }}>Link Sheet Settings</th>
                  <th style={{ width: '10%' }}>Langganan status</th>
                  <th style={{ width: '10%' }}>Tanggal Join langganan</th>
                  <th style={{ width: '10%' }}>Tanggal Closed langganan</th>
                  <th style={{ width: '15%' }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {getCurrentPageData().map((item, index) => (
                  <tr key={item.id}>
                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                    <td>{item.code_agent}</td>
                    <td>{item.name}</td>
                    <td>{item.group_agent}</td>
                    <td>
                      {item.link}
                      {item.link && (
                        <Button
                          variant="link"
                          onClick={() => openLinkInNewTab(item.link)}
                          style={{ padding: '0', marginLeft: '10px' }}
                        >
                          <FontAwesomeIcon icon={faPaperPlane} />
                        </Button>
                      )}
                    </td>
                    <td>
                      {item.status === "active" ? (
                        <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} />
                      ) : (
                        <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'red' }} />
                      )}
                      {` ${item.status}`}
                    </td>
                    <td>{item.tgl_join}</td>
                    <td>{item.tanggal_closed}</td>
                    <td>
                      <Button variant="info" size="sm" className="mb-2" onClick={() => handleEdit(item)}>
                        <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
                        Ubah
                      </Button>
                      <br />
                      <Button variant="danger" size="sm" onClick={() => handleDeleteAgent(item.id)}>
                        <FontAwesomeIcon icon={faTrash} className="mr-1" />
                        Hapus
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination">
            <Button
              variant="light"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className="mr-1"
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </Button>
            {getPaginationButtons()}
            <Button
              variant="light"
              onClick={handleNextPage}
              disabled={currentPage === pageCount}
              className="ml-1"
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </Button>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{editingAgent ? 'Edit Agent' : 'Tambah Agent'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Kode Agent</label>
            <input 
              type="text" 
              className="form-control" 
              name="code_agent"
              value={editingAgent ? editingAgent.code_agent : newAgent.code_agent}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Nama Website</label>
            <input 
              type="text" 
              className="form-control" 
              name="name"
              value={editingAgent ? editingAgent.name : newAgent.name}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
          <label>Group Agent</label>
          <input 
            type="text" 
            className="form-control" 
            name="group_agent"
            value={editingAgent ? editingAgent.group_agent : newAgent.group_agent}
            onChange={handleInputChange}
          />
        </div>
          <div className="form-group">
            <label>Link Sheet Settings</label>
            <input 
              type="text" 
              className="form-control" 
              name="link"
              value={editingAgent ? editingAgent.link : newAgent.link}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Status Langganan</label>
            <select 
              className="form-control" 
              name="status"
              value={editingAgent ? editingAgent.status : newAgent.status}
              onChange={handleInputChange}
            >
              <option value="active">active</option>
              <option value="non active">non active</option>
            </select>
          </div>
          <div className="form-group">
            <label>Tanggal Join Langganan</label>
            <input 
              type="date" 
              className="form-control" 
              name="tgl_join"
              value={editingAgent ? editingAgent.tgl_join : newAgent.tgl_join}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Tanggal Closed Langganan</label>
            <input 
              type="date" 
              className="form-control" 
              name="tanggal_closed"
              value={editingAgent ? editingAgent.tanggal_closed : newAgent.tanggal_closed}
              onChange={handleInputChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Tutup
          </Button>
          <Button variant="primary" onClick={editingAgent ? handleUpdateAgent : handleAddAgent}>
            Simpan
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DaftarAgent;