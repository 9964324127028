import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { login as apiLogin } from '../services/api';
import logo from '../logo.svg';
import './css/Login.css';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const data = await apiLogin(username, password);
      console.log('Login response:', data);
      localStorage.setItem('accessToken', data.accessToken);
      localStorage.setItem('refreshToken', data.refreshToken);
      login(data.user);
      if (data.user.role === 'admin') {
        navigate('/daftaragent');
      } else if (data.user.role === 'customer') {
        navigate('/customer-dashboard');
      } else if (data.user.role === 'operator') {
        navigate('/profile');
      } else if (data.user.role === 'marketing') {
        navigate('/profile');
      }
      else if (data.user.role === 'pengurus') {
        navigate('/profile');
      }
      else if (data.user.role === 'wakilbos') {
        navigate('/profile');
      }
      else if (data.user.role === 'bos') {
        navigate('/daftaragent');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('Login gagal. Periksa kembali username dan password Anda.');
    }
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <div className="logo-container">
          <img src={logo} alt="Logo" className="logo" />
        </div>
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Username</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username"
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
          </div>
          {error && <p className="error-message">{error}</p>}
          <button type="submit">Login</button>
        </form>
      </div>
    </div>
  );
}

export default Login;
