import axios from 'axios';

const BASE_URL = `${process.env.REACT_APP_API_URL}/api/project-archives`;

const getToken = () => localStorage.getItem('accessToken');

export const fetchProjectArchives = async () => {
  try {
    const token = getToken();
    const response = await axios.get(BASE_URL, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching project archives:', error);
    throw new Error('Failed to fetch project archives');
  }
};