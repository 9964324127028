import axios from 'axios';


const BASE_URL = `${process.env.REACT_APP_API_URL}/api/roles`;
const getToken = () => localStorage.getItem('accessToken');

export const fetchRoles = async () => {
  try {
    const token = getToken();
    const response = await axios.get(BASE_URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch roles');
  }
};

export const addRole = async (newRole) => {
  try {
    const token = getToken();
    const response = await axios.post(BASE_URL, newRole, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to add role');
  }
};

export const updateRole = async (id, updatedRole) => {
  try {
    const token = getToken();
    const response = await axios.put(`${BASE_URL}/${id}`, updatedRole, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to update role');
  }
};

export const deleteRole = async (id) => {
  try {
    const token = getToken();
    await axios.delete(`${BASE_URL}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    throw new Error('Failed to delete role');
  }
};