// DaftarWebsite.js
import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { fetchAgents ,fetchWebsites, addWebsite, updateWebsite, deleteWebsite } from '../services/websiteApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTrash, faCheckCircle, faTimesCircle, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';

function DaftarWebsite() {
  const [agents, setAgents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [showModal, setShowModal] = useState(false);
  const [newWebsite, setNewWebsite] = useState({ name: '', url: '', status: 'active' });
  const [editingWebsite, setEditingWebsite] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const itemsPerPage = 10;
  const maxPagesPerSet = 10;

  useEffect(() => {
    Promise.all([fetchWebsites(), fetchAgents()])
      .then(([websitesData, agentsData]) => {
        setData(websitesData);
        setAgents(agentsData);
        setError(null);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const filteredData = data.filter((item) => {
    const nameMatch = item.name ? item.name.toLowerCase().includes(searchTerm.toLowerCase()) : false;
    const urlMatch = item.url ? item.url.toLowerCase().includes(searchTerm.toLowerCase()) : false;
    const statusMatch = statusFilter === 'all' || item.status === statusFilter;
    return (nameMatch || urlMatch) && statusMatch;
  });

  const pageCount = Math.ceil(filteredData.length / itemsPerPage);

  const getCurrentPageData = () => {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return filteredData.slice(begin, end);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < pageCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const getPaginationButtons = () => {
    const currentPageSet = Math.floor((currentPage - 1) / maxPagesPerSet);
    const startPage = currentPageSet * maxPagesPerSet + 1;
    const endPage = Math.min(startPage + maxPagesPerSet - 1, pageCount);

    const buttons = [];
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <Button
          key={i}
          variant={i === currentPage ? "primary" : "light"}
          onClick={() => handlePageChange(i)}
          className="mr-1"
        >
          {i}
        </Button>
      );
    }

    return buttons;
  };

  const handleShowModal = () => {
    setEditingWebsite(null);
    setNewWebsite({ name: '', url: '', status: 'active' });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setError(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingWebsite) {
      setEditingWebsite(prev => ({ ...prev, [name]: value }));
    } else {
      setNewWebsite(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleAddWebsite = async () => {
    try {
      const addedWebsite = await addWebsite(newWebsite);
      setData([...data, addedWebsite]);
      setNewWebsite({ name: '', url: '', status: 'active' });
      setShowModal(false);
      Swal.fire({
        title: 'Success',
        text: 'Website successfully added.',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  const handleUpdateWebsite = async () => {
    try {
      await updateWebsite(editingWebsite.id, editingWebsite);
      setData((prevData) =>
        prevData.map((website) => (website.id === editingWebsite.id ? { ...website, ...editingWebsite } : website))
      );
      setShowModal(false);
      setEditingWebsite(null);
      Swal.fire({
        title: 'Success',
        text: 'Website successfully updated.',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  const handleDeleteWebsite = async (id) => {
    const result = await Swal.fire({
      title: 'Confirmation',
      text: 'Are you sure you want to delete this website?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    });

    if (result.isConfirmed) {
      try {
        await deleteWebsite(id);
        setData(data.filter(website => website.id !== id));
        if (getCurrentPageData().length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
        Swal.fire({
          title: 'Success',
          text: 'Website successfully deleted.',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: error.message,
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    }
  };

  const handleEdit = (website) => {
    setEditingWebsite(website);
    setShowModal(true);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleStatusFilter = (e) => {
    setStatusFilter(e.target.value);
    setCurrentPage(1);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <div className="card shadow mb-4">
        <div className="card-header py-3 d-flex justify-content-between align-items-center">
          <h6 className="m-0 font-weight-bold text-primary">Daftar Website</h6>
          <div className="d-flex">
            <input 
              type="text" 
              placeholder="Search..." 
              value={searchTerm}
              onChange={handleSearch}
              className="form-control mr-2"
            />
            <select 
              value={statusFilter} 
              onChange={handleStatusFilter}
              className="form-control mr-2"
            >
              <option value="all">All Status</option>
              <option value="active">active</option>
              <option value="inactive">inactive</option>
            </select>
            <Button variant="primary" onClick={handleShowModal}>
              Tambah
            </Button>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
              <thead>
                <tr>
                  <th style={{ width: '5%' }}>No</th>
                  <th style={{ width: '30%' }}>Nama Website</th>
                  <th style={{ width: '30%' }}>URL</th>
                  <th style={{ width: '20%' }}>Status</th>
                  <th style={{ width: '15%' }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {getCurrentPageData().map((item, index) => (
                  <tr key={item.id}>
                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.url}</td>
                    <td>
                      {item.status === "active" ? (
                        <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} />
                      ) : (
                        <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'red' }} />
                      )}
                      {` ${item.status}`}
                    </td>
                    <td>
                      <Button variant="info" size="sm" className="mb-2" onClick={() => handleEdit(item)}>
                        <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
                        Ubah
                      </Button>
                      <br />
                      <Button variant="danger" size="sm" onClick={() => handleDeleteWebsite(item.id)}>
                        <FontAwesomeIcon icon={faTrash} className="mr-1" />
                        Hapus
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination">
            <Button
              variant="light"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className="mr-1"
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </Button>
            {getPaginationButtons()}
            <Button
              variant="light"
              onClick={handleNextPage}
              disabled={currentPage === pageCount}
              className="ml-1"
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </Button>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{editingWebsite ? 'Edit Website' : 'Tambah Website'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
  <div className="form-group">
    <label>Nama Agent</label>
    <select 
      className="form-control" 
      name="name"
      value={editingWebsite ? editingWebsite.name : newWebsite.name}
      onChange={handleInputChange}
    >
      <option value="">Pilih Agent</option>
      {agents.map((agent) => (
        <option key={agent.id} value={agent.name}>
          {agent.name}
        </option>
      ))}
    </select>
  </div>
  <div className="form-group">
    <label>URL</label>
    <input 
      type="text" 
      className="form-control" 
      name="url"
      value={editingWebsite ? editingWebsite.url : newWebsite.url}
      onChange={handleInputChange}
    />
  </div>
  <div className="form-group">
    <label>Status</label>
    <select 
      className="form-control" 
      name="status"
      value={editingWebsite ? editingWebsite.status : newWebsite.status}
      onChange={handleInputChange}
    >
      <option value="active">active</option>
      <option value="inactive">inactive</option>
    </select>
  </div>
</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Tutup
          </Button>
          <Button variant="primary" onClick={editingWebsite ? handleUpdateWebsite : handleAddWebsite}>
            Simpan
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DaftarWebsite;