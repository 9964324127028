import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { fetchRoles, addRole, updateRole, deleteRole } from '../services/roleApi';
import Swal from 'sweetalert2';

function DaftarRoles() {
  const [roles, setRoles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newRole, setNewRole] = useState('');
  const [editingRole, setEditingRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // State untuk fitur pagination dan pencarian
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredRoles, setFilteredRoles] = useState([]);

  const itemsPerPage = 10;
  const maxPagesPerSet = 10;

  useEffect(() => {
    fetchRoles()
      .then((data) => {
        setRoles(data);
        setFilteredRoles(data);
        setError(null);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // Fungsi untuk memfilter data berdasarkan pencarian dan status
  useEffect(() => {
    const filtered = roles.filter((role) =>
      role.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredRoles(filtered);
  }, [roles, searchTerm]);

  const pageCount = Math.ceil(filteredRoles.length / itemsPerPage);

  // Fungsi untuk mendapatkan data halaman saat ini
  const getCurrentPageData = () => {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return filteredRoles.slice(begin, end);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < pageCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const getPaginationButtons = () => {
    const currentPageSet = Math.floor((currentPage - 1) / maxPagesPerSet);
    const startPage = currentPageSet * maxPagesPerSet + 1;
    const endPage = Math.min(startPage + maxPagesPerSet - 1, pageCount);

    const buttons = [];
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <Button
          key={i}
          variant={i === currentPage ? 'primary' : 'light'}
          onClick={() => handlePageChange(i)}
          className="mr-1"
        >
          {i}
        </Button>
      );
    }

    return buttons;
  };

  const handleShowModal = () => {
    setEditingRole(null);
    setNewRole('');
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setError(null);
  };

  const handleInputChange = (e) => {
    if (editingRole) {
      setEditingRole((prev) => ({ ...prev, name: e.target.value }));
    } else {
      setNewRole(e.target.value);
    }
  };

  const handleAddRole = async () => {
    try {
      const addedRole = await addRole({ name: newRole });
      setRoles([...roles, addedRole]);
      setFilteredRoles([...filteredRoles, addedRole]);
      setNewRole('');
      setShowModal(false);
      Swal.fire({
        title: 'Success',
        text: 'Role successfully added.',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  const handleUpdateRole = async () => {
    try {
      await updateRole(editingRole.id, { name: editingRole.name });
      setRoles((prevRoles) =>
        prevRoles.map((role) =>
          role.id === editingRole.id ? { ...role, name: editingRole.name } : role
        )
      );
      setFilteredRoles((prevFiltered) =>
        prevFiltered.map((role) =>
          role.id === editingRole.id ? { ...role, name: editingRole.name } : role
        )
      );
      setShowModal(false);
      setEditingRole(null);
      Swal.fire({
        title: 'Success',
        text: 'Role successfully updated.',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  const handleDeleteRole = async (id) => {
    const result = await Swal.fire({
      title: 'Confirmation',
      text: 'Are you sure you want to delete this role?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    });

    if (result.isConfirmed) {
      try {
        await deleteRole(id);
        setRoles(roles.filter((role) => role.id !== id));
        setFilteredRoles(filteredRoles.filter((role) => role.id !== id));
        Swal.fire({
          title: 'Success',
          text: 'Role successfully deleted.',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: error.message,
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    }
  };

  const handleEdit = (role) => {
    setEditingRole(role);
    setShowModal(true);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <div className="card shadow mb-4">
        <div className="card-header py-3 d-flex justify-content-between align-items-center">
          <h6 className="m-0 font-weight-bold text-primary">Daftar Roles</h6>
          <div className="d-flex">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearch}
              className="form-control mr-2"
            />
            <Button variant="primary" onClick={handleShowModal}>
              Tambah
            </Button>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
              <thead>
                <tr>
                  <th>No</th>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {getCurrentPageData().map((role, index) => (
                  <tr key={role.id}>
                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                    <td>{role.id}</td>
                    <td>{role.name}</td>
                    <td>
                      <Button variant="info" size="sm" className="mr-2" onClick={() => handleEdit(role)}>
                        Edit
                      </Button>
                      <Button variant="danger" size="sm" onClick={() => handleDeleteRole(role.id)}>
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination">
            <Button
              variant="light"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className="mr-1"
            >
              Prev
            </Button>
            {getPaginationButtons()}
            <Button
              variant="light"
              onClick={handleNextPage}
              disabled={currentPage === pageCount}
              className="ml-1"
            >
              Next
            </Button>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{editingRole ? 'Edit Role' : 'Tambah Role'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Role Name</label>
            <input
              type="text"
              className="form-control"
              value={editingRole ? editingRole.name : newRole}
              onChange={handleInputChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Tutup
          </Button>
          <Button variant="primary" onClick={editingRole ? handleUpdateRole : handleAddRole}>
            Simpan
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DaftarRoles;
