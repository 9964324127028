import React, { useState, useEffect } from 'react';
import { Modal, Button,Dropdown } from 'react-bootstrap';
import { fetchProjectBackups, addProjectBackup, updateProjectBackup, deleteProjectBackup, triggerStatusUpdate,uploadServiceAccountFile,downloadRarFile,cleanupLogs   } from '../services/projectBackupApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faBan, faExclamationTriangle, faQuestion, faExclamationCircle, faHourglassHalf, faMinus, faSync, faEdit, faTrash, faChevronLeft, faChevronRight,faDownload,faCheckCircle,faFilter } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import loadingGif from '../assets/loading.gif'; 

function DaftarBackupProject() {

  const [isUpdatingStatuses, setIsUpdatingStatuses] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [rarFile, setRarFile] = useState(null);
  const [editingProject, setEditingProject] = useState(null);
  const [rarServiceAccountFile, setRarServiceAccountFile] = useState(null);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [statusFilter, setStatusFilter] = useState('all');
  
  const [newProject, setNewProject] = useState({
    email: '',
    password: '',
    nama_project: '',
    nama_web: '',
    kode_agent: '',
    tanggal_buat: '',
    group_agent: '',
    PIC: '',
    project_id: '',
    rar_service_account: '',
    status_usage: 'not used',
    bot_email: ''
  });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const itemsPerPage = 20;
  
  const handleShowEmails = (emails) => {
    setSelectedEmails(emails.split('\n').join('\n'));  // Ubah ini
    setShowEmailModal(true);
  };
  const getStatusUsageIcon = (status) => {
    switch(status) {
      case 'used':
        return (
          <span title="Used">
            <FontAwesomeIcon icon={faExclamationTriangle} className="text-warning" />
          </span>
        );
      case 'not used':
        return (
          <span title="Not Used">
            <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
          </span>
        );
      default:
        return (
          <span title="Unknown">
            <FontAwesomeIcon icon={faQuestion} className="text-secondary" />
          </span>
        );
    }
  };
  const handleFileSelect = (event, fileType) => {
    if (fileType === 'service') {
      setSelectedFile(event.target.files[0]);
    } else if (fileType === 'rar') {
      setRarFile(event.target.files[0]);
    }
  };


  useEffect(() => {
    fetchData();
  
    const intervalId = setInterval(() => {
      fetchData();
    }, 60000); // Update setiap 1 menit
  
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    let filtered = data.filter(item =>
      Object.values(item).some(value =>
        value !== null && value !== undefined && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );

    if (statusFilter !== 'all') {
      filtered = filtered.filter(item => {
        if (statusFilter === 'active') {
          return item.status_aktif === 'active';
        } else if (statusFilter === 'error') {
          return item.status_aktif === 'error';
        }
        return item.status_aktif === statusFilter;
      });
    }

    setFilteredData(filtered);
    setCurrentPage(1);  // Reset to first page when filters change
  }, [data, searchTerm, statusFilter]);

  const handleStatusFilterChange = (status) => {
    setStatusFilter(status);
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      const projectBackups = await fetchProjectBackups();
      setData(projectBackups);
      setError(null);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  
  const handleCleanupLogs = async () => {
    try {
      await cleanupLogs();
      Swal.fire('Success', 'Logs have been cleaned up', 'success');
    } catch (error) {
      console.error('Error cleaning up logs:', error);
      Swal.fire('Error', 'Failed to clean up logs', 'error');
    }
  };

  const handleDownloadRar = async (id) => {
    try {
      const response = await downloadRarFile(id);
      
      const contentDisposition = response.headers['content-disposition'];
      let filename = 'download.zip'; // Default filename
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="?(.+)"?/i);
        if (filenameMatch && filenameMatch[1]) {
          filename = decodeURIComponent(filenameMatch[1].replace(/['"]/g, ''));
        }
      }
  
      console.log('Filename from server:', filename);
  
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
  
      console.log('File downloaded:', filename);
      
      // Refresh data setelah download
      await fetchData();
      Swal.fire('Success', `File ${filename} downloaded successfully`, 'success');
    } catch (error) {
      console.error('Error downloading file:', error);
      if (error.response && error.response.status === 404) {
        Swal.fire('Error', 'File not available. Please upload again.', 'error');
      } else {
        Swal.fire('Error', 'Failed to download file', 'error');
      }
      // Refresh data untuk memperbarui status
      await fetchData();
    }
  };
  

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingProject(null);
    setNewProject({
      email: '',
      password: '',
      nama_project: '',
      nama_web: '',
      kode_agent: '',
      tanggal_buat: '',
      group_agent: '',
      PIC: '',
      project_id: '',
      rar_service_account: '',
      status_usage: 'not used',
      bot_email: ''
    });
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingProject) {
      setEditingProject(prev => ({ ...prev, [name]: value }));
    } else {
      setNewProject(prev => ({ ...prev, [name]: value }));
    }
  };

 const handleAddProject = async () => {
  try {
    const formData = new FormData();
    for (const key in newProject) {
      formData.append(key, newProject[key]);
    }
    if (selectedFile) {
      formData.append('serviceAccountFile', selectedFile);
    }
    if (rarFile) {
      formData.append('rarServiceAccountFile', rarFile);
    }
    
    // Tambahkan loading indicator
    Swal.fire({
      title: 'Uploading...',
      text: 'Please wait while we upload your files.',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    console.log('Sending data:', Object.fromEntries(formData));
    await addProjectBackup(formData);
    
    Swal.close(); // Tutup loading indicator
    
    fetchData();
    handleCloseModal();
    Swal.fire({
      title: 'Success',
      text: 'Project backup added successfully',
      icon: 'success',
      confirmButtonText: 'OK'
    });
  } catch (error) {
    console.error('Error in handleAddProject:', error);
    Swal.fire({
      title: 'Error',
      text: error.message || 'Failed to add project backup',
      icon: 'error',
      confirmButtonText: 'OK'
    });
  }
};

  const handleUpdateProject = async () => {
    try {
      const formData = new FormData();
      for (const key in editingProject) {
        formData.append(key, editingProject[key]);
      }
      if (selectedFile) {
        formData.append('serviceAccountFile', selectedFile);
      }
      if (rarFile) {
        formData.append('rarServiceAccountFile', rarFile);
      }
      console.log('Updating with data:', Object.fromEntries(formData));
      await updateProjectBackup(editingProject.id, formData);
      fetchData();
      handleCloseModal();
      Swal.fire({
        title: 'Success',
        text: 'Project backup updated successfully',
        icon: 'success',
        confirmButtonText: 'OK'
      });
    } catch (error) {
      console.error('Error in handleUpdateProject:', error);
      Swal.fire({
        title: 'Error',
        text: error.message || 'Failed to update project backup',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };

  

  const handleTriggerStatusUpdate = async () => {
    try {
      setIsUpdatingStatuses(true);
      console.log('Triggering status update...');
      const result = await triggerStatusUpdate();
      console.log('Status update result:', result);
      
      if (result && result.updatedProjectBackups) {
        // Update local state with the new statuses
        setData(prevData => {
          return prevData.map(project => {
            const updatedProject = result.updatedProjectBackups.find(
              update => update && update.id === project.id
            );
            if (updatedProject) {
              console.log(`Updating project ${project.id} status from ${project.status_aktif} to ${updatedProject.newStatus}`);
              return { ...project, status_aktif: updatedProject.newStatus };
            }
            return project;
          });
        });
      } else {
        console.warn('Unexpected response structure:', result);
        // If the response structure is unexpected, fetch all data again
        await fetchData();
      }

      Swal.fire({
        title: 'Success',
        text: 'Status update completed successfully',
        icon: 'success',
        confirmButtonText: 'OK'
      });
    } catch (error) {
      console.error('Error in handleTriggerStatusUpdate:', error);
      Swal.fire({
        title: 'Error',
        text: error.message || 'Failed to update statuses',
        icon: 'error',
        confirmButtonText: 'OK'
      });
      // In case of error, fetch all data again to ensure consistency
      await fetchData();
    } finally {
      setIsUpdatingStatuses(false);
    }
  };

  const handleUploadServiceAccount = async (projectId) => {
    try {
      const { value: file } = await Swal.fire({
        title: 'Select service account file',
        input: 'file',
        inputAttributes: {
          'accept': 'application/json',
          'aria-label': 'Upload your service account file'
        }
      });

      if (file) {
        const formData = new FormData();
        formData.append('serviceAccountFile', file);
        formData.append('projectId', projectId);

        const result = await uploadServiceAccountFile(formData);
        
        Swal.fire({
          title: 'Success',
          text: 'Service account file uploaded successfully',
          icon: 'success',
          confirmButtonText: 'OK'
        });

        fetchData(); // Refresh data after upload
      }
    } catch (error) {
      console.error('Error in handleUploadServiceAccount:', error);
      Swal.fire({
        title: 'Error',
        text: error.message || 'Failed to upload service account file',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };
  const handleDeleteProject = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });

      if (result.isConfirmed) {
        await deleteProjectBackup(id);
        fetchData();
        Swal.fire(
          'Deleted!',
          'Project backup has been deleted.',
          'success'
        );
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };

  const getStatusIcon = (status) => {
    console.log(`Rendering icon for status: ${status}`);
    switch(status) {
      case 'active':
        return <FontAwesomeIcon icon={faCheck} className="text-success" title="Active" />;
      case 'inactive':
        return <FontAwesomeIcon icon={faTimes} className="text-danger" title="Inactive" />;
      case 'pending':
        return <FontAwesomeIcon icon={faHourglassHalf} className="text-info" title="Pending" />;
      case 'banned':
        return <FontAwesomeIcon icon={faBan} className="text-warning" title="Banned" />;
      case 'invalid':
        return <FontAwesomeIcon icon={faExclamationTriangle} className="text-danger" title="Invalid" />;
      case 'unknown':
        return <FontAwesomeIcon icon={faQuestion} className="text-secondary" title="Unknown" />;
      case 'error':
        return <FontAwesomeIcon icon={faExclamationCircle} className="text-danger" title="Error" />;
      default:
        console.warn(`Unhandled status: ${status}`);
        return <FontAwesomeIcon icon={faQuestion} className="text-secondary" title={`Unknown: ${status}`} />;
    }
  };

  const pageCount = Math.ceil(filteredData.length / itemsPerPage);

  const getCurrentPageData = () => {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return filteredData.slice(begin, end);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      {isUpdatingStatuses && (
        <div 
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999
          }}
        >
          <div
            style={{
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <img src={loadingGif} alt="Loading" style={{ width: '250px', height: '250px' }} />
            <p style={{ marginTop: '10px', color: 'black' }}>Updating Statuses...</p>
          </div>
        </div>
      )}
       <div className="card shadow mb-4">
        <div className="card-header py-3 d-flex justify-content-between align-items-center">
          <h6 className="m-0 font-weight-bold text-primary">Daftar Backup Project</h6>
          <div className="d-flex">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="form-control mr-2"
            />
            
            <Button variant="primary" onClick={handleShowModal} className="mr-2">
              Add Project
            </Button>
            <Dropdown className='mr-2'>
              <Dropdown.Toggle variant="primary" id="dropdown-status-filter">
                <FontAwesomeIcon icon={faFilter} /> Status: {statusFilter === 'all' ? 'All' : statusFilter}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleStatusFilterChange('all')}>All</Dropdown.Item>
                <Dropdown.Item onClick={() => handleStatusFilterChange('active')}>Active</Dropdown.Item>
                <Dropdown.Item onClick={() => handleStatusFilterChange('error')}>Error</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          <Button variant="primary" onClick={handleTriggerStatusUpdate} disabled={isUpdatingStatuses}>
              <FontAwesomeIcon icon={faSync} /> Update Statuses
            </Button>
            
            <Button variant="info" onClick={handleCleanupLogs} className="ml-2">
            Clean Up Logs
          </Button>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
          <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Email</th>
                  <th>Password</th>
                  <th>Nama Project</th>
                  <th>Nama Web</th>
                  <th>Kode Agent</th>
                  <th>Tanggal Buat</th>
                  <th>Group Agent</th>
                  <th>Status Aktif</th>
                  <th>Status Usage</th>
                  <th>PIC</th>
                  <th>Project ID</th>
                  <th>Service Account File</th>
                  <th>Rar/ZIP/Archive BOT</th>
                  <th>Bot Email</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {getCurrentPageData().map((item, index) => (
                   <tr key={`${item.id}-${item.status_aktif}`}>
                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                    <td>{item.email}</td>
                    <td>{item.password}</td>
                    <td>{item.nama_project}</td>
                    <td>{item.nama_web}</td>
                    <td>{item.kode_agent}</td>
                    <td>{new Date(item.tanggal_buat).toLocaleDateString()}</td>
                    <td>{item.group_agent}</td>
                    <td>{getStatusIcon(item.status_aktif)}</td>
                    <td>{getStatusUsageIcon(item.status_usage)}</td>
                    <td>{item.PIC}</td>
                    <td>{item.project_id}</td>
                    <td>{item.service_account_file}</td>
                    <td>
                      {item.rar_service_account ? (
                        <>
                          {item.rar_service_account}
                          <Button
                            variant="link"
                            size="sm"
                            onClick={() => handleDownloadRar(item.id)}
                          >
                            <FontAwesomeIcon icon={faDownload} />
                          </Button>
                        </>
                      ) : (
                        'No file'
                      )}
                    </td>
                    <td>
                      {item.bot_email ? (
                        <Button variant="info" size="sm" onClick={() => handleShowEmails(item.bot_email)}>
                          View Emails
                        </Button>
                      ) : (
                        'No emails'
                      )}
                    </td>
                    <td>
                      <Button variant="info" size="sm" className="mr-2" onClick={() => { setEditingProject(item); setShowModal(true); }}>
                        <FontAwesomeIcon icon={faEdit} /> Edit
                      </Button>
                      <Button variant="danger" size="sm" onClick={() => handleDeleteProject(item.id)}>
                        <FontAwesomeIcon icon={faTrash} /> Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="card-footer">
          <div className="d-flex justify-content-between align-items-center">
            <div>
            Showing {(currentPage - 1) * itemsPerPage + 1} to {Math.min(currentPage * itemsPerPage, filteredData.length)} of {filteredData.length} entries
            </div>
            <nav>
              <ul className="pagination">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                  <Button className="page-link" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </Button>
                </li>
                {[...Array(pageCount)].map((_, i) => (
                  <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                    <Button className="page-link" onClick={() => handlePageChange(i + 1)}>{i + 1}</Button>
                  </li>
                ))}
                <li className={`page-item ${currentPage === pageCount ? 'disabled' : ''}`}>
                  <Button className="page-link" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === pageCount}>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </Button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{editingProject ? 'Edit Project Backup' : 'Add Project Backup'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label>Email</label>
              <input type="email" className="form-control" name="email" value={editingProject ? editingProject.email : newProject.email} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label>Password</label>
              <input type="password" className="form-control" name="password" value={editingProject ? editingProject.password : newProject.password} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label>Nama Project</label>
              <input type="text" className="form-control" name="nama_project" value={editingProject ? editingProject.nama_project : newProject.nama_project} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label>Nama Web</label>
              <input type="text" className="form-control" name="nama_web" value={editingProject ? editingProject.nama_web : newProject.nama_web} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label>Kode Agent</label>
              <input type="text" className="form-control" name="kode_agent" value={editingProject ? editingProject.kode_agent : newProject.kode_agent} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label>Tanggal Buat</label>
              <input type="date" className="form-control" name="tanggal_buat" value={editingProject ? editingProject.tanggal_buat.split('T')[0] : newProject.tanggal_buat} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label>Group Agent</label>
              <input type="text" className="form-control" name="group_agent" value={editingProject ? editingProject.group_agent : newProject.group_agent} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label>PIC</label>
              <input type="text" className="form-control" name="PIC" value={editingProject ? editingProject.PIC : newProject.PIC} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label>Project ID (Google Cloud)</label>
              <input type="text" className="form-control" name="project_id" value={editingProject ? editingProject.project_id : newProject.project_id} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label>Service Account File</label>
              <input
                type="file"
                className="form-control-file"
                onChange={(e) => handleFileSelect(e, 'service')}
                accept=".json"
              />
            </div>
            <div className="form-group">
              <label>RAR Service Account</label>
              <input
                type="file"
                className="form-control-file"
                onChange={(e) => handleFileSelect(e, 'rar')}
                accept=".rar,.zip"
              />
            </div>
            <div className="form-group">
              <label>Status Usage</label>
              <select className="form-control" name="status_usage" value={editingProject ? editingProject.status_usage : newProject.status_usage} onChange={handleInputChange}>
                <option value="not used">Not Used</option>
                <option value="used">Used</option>
              </select>
            </div>
            <div className="form-group">
              <label>Bot Email</label>
              <textarea className="form-control" name="bot_email" value={editingProject ? editingProject.bot_email : newProject.bot_email} onChange={handleInputChange} rows="3"></textarea>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={editingProject ? handleUpdateProject : handleAddProject}>
            {editingProject ? 'Update' : 'Add'}
          </Button>
        </Modal.Footer>
      </Modal>

     <Modal show={showEmailModal} onHide={() => setShowEmailModal(false)}>
  <Modal.Header closeButton>
    <Modal.Title>Bot Emails</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {selectedEmails.split('\n').map((email, index) => (
      <p key={index}>{email.trim()}</p>
    ))}
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowEmailModal(false)}>
      Close
    </Button>
    <Button variant="primary" onClick={() => {
      navigator.clipboard.writeText(selectedEmails);
      Swal.fire('Copied!', 'Emails have been copied to clipboard', 'success');
    }}>
      Copy Emails
    </Button>
  </Modal.Footer>
</Modal>
    </div>
  );
}

export default DaftarBackupProject;