import axios from 'axios';

const BASE_URL = `${process.env.REACT_APP_API_URL}/api/users`;
const getToken = () => localStorage.getItem('accessToken');

export const fetchUsers = async () => {
  try {
    const token = getToken();
    const response = await axios.get(BASE_URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Gagal mengambil data pengguna');
  }
};

// export const addUser = async (newUser) => {
//   try {
//     const token = getToken();
//     const response = await axios.post(BASE_URL, newUser, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//         'Content-Type': 'application/json',
//       },
//     });
//     return response.data;
//   } catch (error) {
//     if (error.response && error.response.status === 400) {
//       throw new Error('Username sudah digunakan. Silakan pilih username lain.');
//     } else {
//       console.error('Error adding user:', error.response?.data || error.message);
//       throw new Error('Gagal menambahkan pengguna');
//     }
//   }
// };
export const addUser = async (newUser) => {
  try {
    const token = getToken();
    console.log('Adding new user:', newUser);
    const response = await axios.post(BASE_URL, newUser, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    console.log('User added successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error adding user:', error.response?.data || error.message);
    if (error.response && error.response.status === 400) {
      throw new Error(error.response.data.message || 'Username sudah digunakan. Silakan pilih username lain.');
    } else if (error.response && error.response.status === 403) {
      throw new Error(error.response.data.message || 'Anda tidak memiliki izin untuk melakukan operasi ini.');
    } else {
      throw new Error('Gagal menambahkan pengguna');
    }
  }
};

export const updateUser = async (id, updatedUser) => {
  try {
    const token = getToken();
    console.log(`Updating user with id: ${id}`, updatedUser);
    const response = await axios.put(`${BASE_URL}/${id}`, updatedUser, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    console.log('User updated successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating user:', error.response?.data || error.message);
    throw new Error(error.response?.data?.message || 'Gagal memperbarui pengguna');
  }
};
export const deleteUser = async (id) => {
  try {
    const token = getToken();
    console.log(`Deleting user with id: ${id}`);
    const response = await axios.delete(`${BASE_URL}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log('User deleted successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error deleting user:', error.response?.data || error.message);
    throw new Error(error.response?.data?.message || 'Gagal menghapus pengguna');
  }
};

export const fetchRoles = async () => {
  try {
    const token = getToken();
    const response = await axios.get(`${BASE_URL}/roles`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Gagal mengambil data peran');
  }
};