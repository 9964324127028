import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext'; 
import LogoutLink from './LogoutLink'// Pastikan path ini benar

const Sidebar = () => {
  const { user } = useAuth();

  return (
    <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
      {/* Sidebar - Brand */}
      <Link className="sidebar-brand d-flex align-items-center justify-content-center" to={user.role === 'admin' ? '/daftaragent' : '/customer-dashboard'}>
        <div className="sidebar-brand-icon rotate-n-15">
          <i className="fas fa-link"></i>
        </div>
        <div className="sidebar-brand-text mx-3">TS App<sup> 2</sup></div>
      </Link>

      {/* Divider */}
      <hr className="sidebar-divider my-0" />

      {user.role === 'admin' && (
        <>
          <div className="sidebar-heading">
            Daftar
          </div>
          {/* Nav Items untuk Admin */}
          {/* <li className="nav-item">
            <Link className="nav-link" to="/daftarroles">
              <i className="fas fa-fw fa-users"></i>
              <span>Daftar Roles</span>
            </Link>
          </li> */}
        
          {/* <li className="nav-item">
            <Link className="nav-link" to="/daftaruser">
              <i className="fas fa-fw fa-users"></i>
              <span>Daftar Users</span>
            </Link>
          </li> */}
          {/* <li className="nav-item">
            <Link className="nav-link" to="/daftarwebsite">
              <i className="fas fa-fw fa-globe"></i>
              <span>Daftar Website</span>
            </Link>
          </li> */}
          {/* <li className="nav-item">
                <Link className="nav-link" to="/daftarpegawai" data-toggle="collapse" data-target="#collapsePages" aria-expanded="true"
                    aria-controls="collapsePages">
                    <i className="fas fa-fw fa-folder"></i>
                    <span>Daftar Pegawai</span>
                </Link>
                <div id="collapsePages" className="collapse show" aria-labelledby="headingPages"
                    data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Lainnya:</h6>
                        <Link className="collapse-item" to="/dendakaryawan">ST/Denda</Link>
                        <Link className="collapse-item" to="/editjadwal">EDIT JADWAL</Link>
                        <Link className="collapse-item" to="/jadwalpekerja">JADWAL</Link>
                    </div>
                </div>
            </li> */}
          <li className="nav-item">
            <Link className="nav-link" to="/daftaragent">
              <i className="fas fa-fw fa-users"></i>
              <span>Daftar Agent</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/automember">
              <i className="fas fa-fw fa-user-plus"></i>
              <span>Daftar Project GCP dipakai</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/daftarbackupproject">
              <i className="fas fa-fw fa-database"></i>
              <span>Daftar Backup Project</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/daftarlistemail">
              <i className="fas fa-fw fa-envelope"></i>
              <span>Daftar List Email</span>
            </Link>
          </li>
          <hr className="sidebar-divider" />
          <li className="nav-item">
            <Link className="nav-link" to="/bugreports">
              <i className="fas fa-fw fa-bug"></i>
              <span>Daftar Fix Bug/Error</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/projectarchive">
              <i className="fas fa-fw fa-archive"></i>
              <span>Archive Project Ke banned</span>
            </Link>
          </li>
          <hr className="sidebar-divider" />
        </>
      )}

      {user.role === 'operator' && (
        <>
          <div className="sidebar-heading">
            Customer
          </div>
          <li className="nav-item">
            <Link className="nav-link" to="/customer-dashboard">
              <i className="fas fa-fw fa-tachometer-alt"></i>
              <span>Dashboard</span>
            </Link>
          </li>
          {/* <hr className="sidebar-divider" /> */}
        </>
      )}

      {/* Notifikasi untuk kedua role */}
      {/* <li className="nav-item">
        <Link className="nav-link" to="/notifikasi">
          <i className="fas fa-fw fa-bell"></i>
          <span>Kirim Notifikasi</span>
        </Link>
      </li> */}
      <li className="nav-item">
        <Link className="nav-link" to="/profile">
          <i className="fas fa-fw fa-user"></i>
          <span>Profile</span>
        </Link>
      </li>
      <li className="nav-item">
          <span><LogoutLink /></span>
      </li>
      <hr className="sidebar-divider" />
    </ul>
  );
};

export default Sidebar;