import React, { useState } from 'react';
import { uploadFile } from '../services/fileApi';

const Notifikasi = () => {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Silakan pilih file terlebih dahulu!');
      return;
    }

    try {
      const response = await uploadFile(file);
      alert(`File berhasil diunggah! Nama file: ${response.filename}`);
    } catch (error) {
      console.error('Error mengunggah file:', error);
      alert('Terjadi kesalahan saat mengunggah file');
    }
  };

  return (
    <div>
      <h2>Halaman Notifikasi</h2>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Unggah</button>
    </div>
  );
};

export default Notifikasi;