import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { fetchBugReports, addBugReport, updateBugReport, deleteBugReport } from '../services/bugReportApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';

function BugReports() {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [editingBugReport, setEditingBugReport] = useState(null);
  const [newBugReport, setNewBugReport] = useState({
    type_kendala: '',
    kendala: '',
    solusi_fix_bug: '',
    tanggal_bug: '',
    PIC: ''
  });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const itemsPerPage = 20;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const bugReports = await fetchBugReports();
      setData(bugReports);
      setError(null);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingBugReport(null);
    setNewBugReport({
      type_kendala: '',
      kendala: '',
      solusi_fix_bug: '',
      tanggal_bug: '',
      PIC: ''
    });
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingBugReport) {
      setEditingBugReport(prev => ({ ...prev, [name]: value }));
    } else {
      setNewBugReport(prev => ({ ...prev, [name]: value }));
    }
  };

  function formatDateForInput(dateString) {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  }
  
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
    
    if (file && allowedTypes.includes(file.type)) {
      if (editingBugReport) {
        setEditingBugReport(prev => ({ ...prev, screenshot_kendala: file }));
      } else {
        setNewBugReport(prev => ({ ...prev, screenshot_kendala: file }));
      }
    } else {
      // Reset the file input
      e.target.value = '';
      // Show an error message to the user
      Swal.fire({
        title: 'Error',
        text: 'Please select a valid image file (JPEG, PNG, GIF, or WebP).',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };
  const handleAddBugReport = async () => {
    try {
      const formData = new FormData();
      Object.keys(newBugReport).forEach(key => {
        formData.append(key, newBugReport[key]);
      });
      const response = await addBugReport(formData);
      if (response.status === 400) {
        throw new Error(response.data.message);
      }
      fetchData();
      handleCloseModal();
      Swal.fire({
        title: 'Success',
        text: 'Bug report added successfully',
        icon: 'success',
        confirmButtonText: 'OK'
      });
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };

  const handleUpdateBugReport = async () => {
    try {
      const formData = new FormData();
      Object.keys(editingBugReport).forEach(key => {
        formData.append(key, editingBugReport[key]);
      });
      await updateBugReport(editingBugReport.id, formData);
      fetchData();
      handleCloseModal();
      Swal.fire({
        title: 'Success',
        text: 'Bug report updated successfully',
        icon: 'success',
        confirmButtonText: 'OK'
      });
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };

  const handleDeleteBugReport = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });

      if (result.isConfirmed) {
        await deleteBugReport(id);
        fetchData();
        Swal.fire(
          'Deleted!',
          'Bug report has been deleted.',
          'success'
        );
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };

  const filteredData = data.filter(item =>
    Object.values(item).some(value =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const pageCount = Math.ceil(filteredData.length / itemsPerPage);

  const getCurrentPageData = () => {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return filteredData.slice(begin, end);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <div className="card shadow mb-4">
        <div className="card-header py-3 d-flex justify-content-between align-items-center">
          <h6 className="m-0 font-weight-bold text-primary">Bug Reports</h6>
          <div className="d-flex">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="form-control mr-2"
            />
           <Button variant="primary" onClick={handleShowModal}>
              Add Bug Report
            </Button>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Type Kendala</th>
                  <th>Kendala</th>
                  <th>Screenshot</th>
                  <th>Solusi Fix Bug</th>
                  <th>Tanggal Bug</th>
                  <th>PIC</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {getCurrentPageData().map((item, index) => (
                  <tr key={item.id}>
                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                    <td>{item.type_kendala}</td>
                    <td>{item.kendala}</td>
                    <td>
                      {item.screenshot_kendala && (
                        <a href={`${process.env.REACT_APP_API_URL}/uploads/${item.screenshot_kendala}`} target="_blank" rel="noopener noreferrer">
                          View Screenshot
                        </a>
                      )}
                    </td>
                    <td>{item.solusi_fix_bug}</td>
                    <td>{new Date(item.tanggal_bug).toLocaleDateString()}</td>
                    <td>{item.PIC}</td>
                    <td>
                      <Button variant="info" size="sm" className="mr-2" onClick={() => { setEditingBugReport(item); setShowModal(true); }}>
                        <FontAwesomeIcon icon={faEdit} /> Edit
                      </Button>
                      <Button variant="danger" size="sm" onClick={() => handleDeleteBugReport(item.id)}>
                        <FontAwesomeIcon icon={faTrash} /> Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="card-footer">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              Showing {(currentPage - 1) * itemsPerPage + 1} to {Math.min(currentPage * itemsPerPage, filteredData.length)} of {filteredData.length} entries
            </div>
            <nav>
              <ul className="pagination">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                  <Button className="page-link" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </Button>
                </li>
                {[...Array(pageCount)].map((_, i) => (
                  <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                    <Button className="page-link" onClick={() => handlePageChange(i + 1)}>{i + 1}</Button>
                  </li>
                ))}
                <li className={`page-item ${currentPage === pageCount ? 'disabled' : ''}`}>
                  <Button className="page-link" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === pageCount}>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </Button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{editingBugReport ? 'Edit Bug Report' : 'Add Bug Report'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label>Type Kendala</label>
              <input type="text" className="form-control" name="type_kendala" value={editingBugReport ? editingBugReport.type_kendala : newBugReport.type_kendala} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label>Kendala</label>
              <textarea className="form-control" name="kendala" value={editingBugReport ? editingBugReport.kendala : newBugReport.kendala} onChange={handleInputChange}></textarea>
            </div>
            <div className="form-group">
            <label>Screenshot Kendala</label>
            <input 
              type="file" 
              className="form-control-file" 
              onChange={handleFileChange}
              accept="image/*"  // This limits file selection in the browser
            />
          </div>
            <div className="form-group">
              <label>Solusi Fix Bug</label>
              <textarea className="form-control" name="solusi_fix_bug" value={editingBugReport ? editingBugReport.solusi_fix_bug : newBugReport.solusi_fix_bug} onChange={handleInputChange}></textarea>
            </div>
            <div className="form-group">
            <label>Tanggal Bug</label>
            <input 
              type="date" 
              className="form-control" 
              name="tanggal_bug" 
              value={editingBugReport ? formatDateForInput(editingBugReport.tanggal_bug) : newBugReport.tanggal_bug} 
              onChange={handleInputChange} 
            />
          </div>
            <div className="form-group">
              <label>PIC</label>
              <input type="text" className="form-control" name="PIC" value={editingBugReport ? editingBugReport.PIC : newBugReport.PIC} onChange={handleInputChange} />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={editingBugReport ? handleUpdateBugReport : handleAddBugReport}>
            {editingBugReport ? 'Update' : 'Add'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default BugReports;