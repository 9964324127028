import axios from 'axios';
import { saveAs } from 'file-saver';

const BASE_URL = `${process.env.REACT_APP_API_URL}/api/project-backups`;

const getToken = () => localStorage.getItem('accessToken');

export const fetchProjectBackups = async () => {
  try {
    const token = getToken();
    const response = await axios.get(BASE_URL, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching project backups:', error);
    throw new Error('Failed to fetch project backups');
  }
};

export const addProjectBackup = async (formData) => {
  try {
    const token = getToken();
    const response = await axios.post(BASE_URL, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error adding project backup:', error);
    throw new Error('Failed to add project backup');
  }
};

export const updateProjectBackup = async (id, formData) => {
  try {
    const token = getToken();
    const response = await axios.put(`${BASE_URL}/${id}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating project backup:', error);
    throw new Error(error.response?.data?.message || 'Failed to update project backup');
  }
};

export const uploadServiceAccountFile = async (formData) => {
  try {
    const token = getToken();
    const response = await axios.post(`${BASE_URL}/upload-service-account`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading service account file:', error);
    throw new Error('Failed to upload service account file');
  }
};

export const deleteProjectBackup = async (id) => {
  try {
    const token = getToken();
    await axios.delete(`${BASE_URL}/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (error) {
    console.error('Error deleting project backup:', error);
    throw new Error('Failed to delete project backup');
  }
};

export const triggerStatusUpdate = async () => {
  try {
    const token = getToken();
    const response = await axios.post(`${BASE_URL}/trigger-status-update`, {}, {
      headers: { Authorization: `Bearer ${token}` },
    });
    console.log('Trigger status update response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error triggering status update:', error);
    throw new Error(error.response?.data?.message || 'Failed to trigger status update');
  }
};

export const downloadRarFile = async (id) => {
  try {
    const token = getToken();
    const response = await axios.get(`${BASE_URL}/download-rar/${id}`, {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    return response;
  } catch (error) {
    console.error('Error downloading RAR file:', error);
    throw error;
  }
};

export const cleanupLogs = async () => {
  try {
    const token = getToken();
    const response = await axios.post(`${BASE_URL}/cleanup-logs`, null, {
      headers: { Authorization: `Bearer ${token}` },
    });
    console.log('Cleanup logs response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error cleaning up logs:', error);
    throw new Error(error.response?.data?.message || 'Failed to clean up logs');
  }
};
