import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { fetchUsers, addUser, updateUser, deleteUser } from '../services/userApi';
import { fetchRoles } from '../services/roleApi';
import Swal from 'sweetalert2';
import 'bootstrap/dist/css/bootstrap.min.css';

function DaftarUser() {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [newUser, setNewUser] = useState({ username: '', password: '', fullName: '', roleId: '' });
  const [editUser, setEditUser] = useState({ id: '', username: '', fullName: '', roleId: '' });
  const itemsPerPage = 20;

  useEffect(() => {
    fetchUsersData();
    fetchRolesData();
  }, []);

  const fetchUsersData = async () => {
    try {
      const data = await fetchUsers();
      setUsers(data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchRolesData = async () => {
    try {
      const data = await fetchRoles();
      setRoles(data);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  const handleAddUser = async (e) => {
    e.preventDefault();
    try {
      const userToAdd = {
        ...newUser,
        roleId: Number(newUser.roleId)
      };
      await addUser(userToAdd);
      setShowModal(false);
      setNewUser({ username: '', password: '', fullName: '', roleId: '' });
      fetchUsersData();
      Swal.fire('Berhasil!', 'Pengguna berhasil ditambahkan.', 'success');
    } catch (error) {
      console.error('Error adding user:', error);
      Swal.fire('Gagal!', `Terjadi kesalahan: ${error.message}`, 'error');
    }
  };

  const handleDeleteUser = async (id) => {
    Swal.fire({
      title: 'Apakah Anda yakin?',
      text: 'Pengguna ini akan dihapus secara permanen!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, hapus!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteUser(id);
          fetchUsersData();
          Swal.fire('Berhasil!', 'Pengguna berhasil dihapus.', 'success');
        } catch (error) {
          console.error('Error deleting user:', error);
          Swal.fire('Gagal!', `Terjadi kesalahan: ${error.message}`, 'error');
        }
      }
    });
  };

  const handleEditUser = async (e) => {
    e.preventDefault();
    try {
      const userToUpdate = {
        ...editUser,
        roleId: Number(editUser.roleId)
      };
      await updateUser(editUser.id, userToUpdate);
      setShowEditModal(false);
      fetchUsersData();
      Swal.fire('Berhasil!', 'Pengguna berhasil diperbarui.', 'success');
    } catch (error) {
      console.error('Error updating user:', error);
      Swal.fire('Gagal!', `Terjadi kesalahan: ${error.message}`, 'error');
    }
  };

  const handleOpenEditModal = (user) => {
    setEditUser({
      id: user.id,
      username: user.username,
      fullName: user.full_name,
      roleId: user.role_id.toString()
    });
    setShowEditModal(true);
  };

  const filteredUsers = users.filter(user =>
    Object.values(user).some(value =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const pageCount = Math.ceil(filteredUsers.length / itemsPerPage);

  const getCurrentPageData = () => {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return filteredUsers.slice(begin, end);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < pageCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="container-fluid">
      <div className="card shadow mb-4">
        <div className="card-header py-3 d-flex justify-content-between align-items-center">
          <h6 className="m-0 font-weight-bold text-primary">Daftar Pengguna</h6>
          <div className="d-flex">
            <input
              type="text"
              placeholder="Cari..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="form-control mr-2"
            />
            <button className="btn btn-primary" onClick={() => setShowModal(true)}>
              <FontAwesomeIcon icon={faPlus} /> Tambah Pengguna
            </button>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Username</th>
                  <th>Nama Lengkap</th>
                  <th>Peran</th>
                  <th>Aksi</th>
                </tr>
              </thead>
              <tbody>
                {getCurrentPageData().map((user, index) => (
                  <tr key={user.id}>
                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                    <td>{user.username}</td>
                    <td>{user.full_name}</td>
                    <td>{roles.find(role => role.id === user.role_id)?.name}</td>
                    <td>
                      <button
                        className="btn btn-sm btn-warning mr-2"
                        onClick={() => handleOpenEditModal(user)}
                      >
                        <FontAwesomeIcon icon={faEdit} /> Edit
                      </button>
                      <button
                        className="btn btn-sm btn-danger"
                        onClick={() => handleDeleteUser(user.id)}
                      >
                        <FontAwesomeIcon icon={faTrash} /> Hapus
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="card-footer">
          <nav>
            <ul className="pagination justify-content-center">
              <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                <button className="page-link" onClick={handlePreviousPage}>
                  Previous
                </button>
              </li>
              {[...Array(pageCount)].map((_, i) => (
                <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                  <button className="page-link" onClick={() => handlePageChange(i + 1)}>
                    {i + 1}
                  </button>
                </li>
              ))}
              <li className={`page-item ${currentPage === pageCount ? 'disabled' : ''}`}>
                <button className="page-link" onClick={handleNextPage}>Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      {/* Modal Tambah Pengguna */}
      {showModal && (
        <div className="modal" style={{display: 'block', backgroundColor: 'rgba(0,0,0,0.5)'}}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Tambah Pengguna Baru</h5>
                <button type="button" className="close" onClick={() => setShowModal(false)}>
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleAddUser}>
                  <div className="form-group">
                    <label>Username</label>
                    <input
                      type="text"
                      className="form-control"
                      value={newUser.username}
                      onChange={(e) => setNewUser({...newUser, username: e.target.value})}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      className="form-control"
                      value={newUser.password}
                      onChange={(e) => setNewUser({...newUser, password: e.target.value})}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Nama Lengkap</label>
                    <input
                      type="text"
                      className="form-control"
                      value={newUser.fullName}
                      onChange={(e) => setNewUser({...newUser, fullName: e.target.value})}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Peran</label>
                    <select
                      className="form-control"
                      value={newUser.roleId}
                      onChange={(e) => setNewUser({...newUser, roleId: e.target.value})}
                      required
                    >
                      <option value="">Pilih Peran</option>
                      {roles.map(role => (
                        <option key={role.id} value={role.id}>{role.name}</option>
                      ))}
                    </select>
                  </div>
                  <button type="submit" className="btn btn-primary">Tambah Pengguna</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal Edit Pengguna */}
      {showEditModal && (
        <div className="modal" style={{display: 'block', backgroundColor: 'rgba(0,0,0,0.5)'}}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Pengguna</h5>
                <button type="button" className="close" onClick={() => setShowEditModal(false)}>
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleEditUser}>
                  <div className="form-group">
                    <label>Username</label>
                    <input
                      type="text"
                      className="form-control"
                      value={editUser.username}
                      onChange={(e) => setEditUser({...editUser, username: e.target.value})}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Nama Lengkap</label>
                    <input
                      type="text"
                      className="form-control"
                      value={editUser.fullName}
                      onChange={(e) => setEditUser({...editUser, fullName: e.target.value})}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Peran</label>
                    <select
                      className="form-control"
                      value={editUser.roleId}
                      onChange={(e) => setEditUser({...editUser, roleId: e.target.value})}
                      required
                    >
                      {roles.map(role => (
                        <option key={role.id} value={role.id}>{role.name}</option>
                      ))}
                    </select>
                  </div>
                  <button type="submit" className="btn btn-primary">Simpan Perubahan</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DaftarUser;
