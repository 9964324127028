import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { fetchEmails, addEmail, updateEmail, deleteEmail } from '../services/emailListApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTrash, faExclamationTriangle, faTimes, faTimesCircle, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';

function DaftarListEmail() {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [showModal, setShowModal] = useState(false);
  const [newEmail, setNewEmail] = useState({
    email: '',
    password: '',
    daftarproject: '',
    status_email: 'belum digunakan'
  });
  const [editingEmail, setEditingEmail] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const itemsPerPage = 10;
  const maxPagesPerSet = 10;

  useEffect(() => {
    fetchEmails()
      .then((data) => {
        setData(data);
        setError(null);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const filteredData = data.filter((item) => {
    const emailMatch = item.email.toLowerCase().includes(searchTerm.toLowerCase());
    const statusMatch = statusFilter === 'all' || item.status_email === statusFilter;
    return emailMatch && statusMatch;
  });

  const pageCount = Math.ceil(filteredData.length / itemsPerPage);

  const getCurrentPageData = () => {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return filteredData.slice(begin, end);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < pageCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const getPaginationButtons = () => {
    const currentPageSet = Math.floor((currentPage - 1) / maxPagesPerSet);
    const startPage = currentPageSet * maxPagesPerSet + 1;
    const endPage = Math.min(startPage + maxPagesPerSet - 1, pageCount);

    const buttons = [];
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <Button
          key={i}
          variant={i === currentPage ? "primary" : "light"}
          onClick={() => handlePageChange(i)}
          className="mr-1"
        >
          {i}
        </Button>
      );
    }

    return buttons;
  };

  const handleShowModal = () => {
    setEditingEmail(null);
    setNewEmail({
      email: '',
      password: '',
      daftarproject: '',
      status_email: 'belum digunakan'
    });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setError(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingEmail) {
      setEditingEmail(prev => ({ ...prev, [name]: value }));
    } else {
      setNewEmail(prev => ({ ...prev, [name]: value }));
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'belum digunakan':
        return <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: 'yellow' }} />;
      case 'digunakan':
        return <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: 'yellow' }} />;
      case 'verif':
        return <FontAwesomeIcon icon={faTimes} style={{ color: 'red' }} />;
      default:
        return null;
    }
  };

  const handleAddEmail = async () => {
    try {
      const addedEmail = await addEmail(newEmail);
      setData([...data, addedEmail]);
      setNewEmail({
        email: '',
        password: '',
        daftarproject: '',
        status_email: 'belum digunakan'
      });
      setShowModal(false);
      Swal.fire({
        title: 'Success',
        text: 'Email successfully added.',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  const handleUpdateEmail = async () => {
    try {
      await updateEmail(editingEmail.id, editingEmail);
      setData((prevData) =>
        prevData.map((email) => (email.id === editingEmail.id ? editingEmail : email))
      );
      setShowModal(false);
      setEditingEmail(null);
      Swal.fire({
        title: 'Success',
        text: 'Email successfully updated.',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  const handleDeleteEmail = async (id) => {
    const result = await Swal.fire({
      title: 'Confirmation',
      text: 'Are you sure you want to delete this email?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    });

    if (result.isConfirmed) {
      try {
        await deleteEmail(id);
        setData(data.filter(email => email.id !== id));
        if (getCurrentPageData().length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
        Swal.fire({
          title: 'Success',
          text: 'Email successfully deleted.',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: error.message,
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    }
  };

  const handleEdit = (email) => {
    setEditingEmail(email);
    setShowModal(true);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleStatusFilter = (e) => {
    setStatusFilter(e.target.value);
    setCurrentPage(1);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <div className="card shadow mb-4">
        <div className="card-header py-3 d-flex justify-content-between align-items-center">
          <h6 className="m-0 font-weight-bold text-primary">Daftar Email</h6>
          <div className="d-flex">
            <input 
              type="text" 
              placeholder="Search..." 
              value={searchTerm}
              onChange={handleSearch}
              className="form-control mr-2"
            />
            <select 
              value={statusFilter} 
              onChange={handleStatusFilter}
              className="form-control mr-2"
            >
              <option value="all">All Status</option>
              <option value="digunakan">Digunakan</option>
              <option value="belum digunakan">Belum Digunakan</option>
            </select>
            <Button variant="primary" onClick={handleShowModal}>
              Tambah
            </Button>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
              <thead>
                <tr>
                  <th style={{ width: '5%' }}>No</th>
                  <th style={{ width: '35%' }}>Email</th>
                  <th style={{ width: '35%' }}>Daftar Project</th>
                  <th style={{ width: '10%' }}>Status</th>
                  <th style={{ width: '15%' }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {getCurrentPageData().map((item, index) => (
                  <tr key={item.id}>
                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                    <td>{item.email}</td>
                    <td>{item.daftarproject}</td>
                    <td>
                      {getStatusIcon(item.status_email)}
                      {` ${item.status_email}`}
                    </td>
                    <td>
                      <Button variant="info" size="sm" className="mb-2" onClick={() => handleEdit(item)}>
                        <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
                        Ubah
                      </Button>
                      <br />
                      <Button variant="danger" size="sm" onClick={() => handleDeleteEmail(item.id)}>
                        <FontAwesomeIcon icon={faTrash} className="mr-1" />
                        Hapus
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination">
            <Button
              variant="light"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className="mr-1"
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </Button>
            {getPaginationButtons()}
            <Button
              variant="light"
              onClick={handleNextPage}
              disabled={currentPage === pageCount}
              className="ml-1"
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </Button>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{editingEmail ? 'Edit Email' : 'Tambah Email'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Email</label>
            <input 
              type="email" 
              className="form-control" 
              name="email"
              value={editingEmail ? editingEmail.email : newEmail.email}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input 
              type="password" 
              className="form-control" 
              name="password"
              value={editingEmail ? editingEmail.password : newEmail.password}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Daftar Project</label>
            <input 
              type="text" 
              className="form-control" 
              name="daftarproject"
              value={editingEmail ? editingEmail.daftarproject : newEmail.daftarproject}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Status</label>
            <select 
              className="form-control" 
              name="status_email"
              value={editingEmail ? editingEmail.status_email : newEmail.status_email}
              onChange={handleInputChange}
            >
              <option value="digunakan">Digunakan</option>
              <option value="belum digunakan">Belum Digunakan</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Tutup
          </Button>
          <Button variant="primary" onClick={editingEmail ? handleUpdateEmail : handleAddEmail}>
            Simpan
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DaftarListEmail;