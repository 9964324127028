import axios from 'axios';

const BASE_URL = `${process.env.REACT_APP_API_URL}/api/project-details`;

const getToken = () => localStorage.getItem('accessToken');

export const fetchProjectDetails = async () => {
  try {
    const token = getToken();
    const response = await axios.get(BASE_URL, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching project details:', error);
    throw new Error('Failed to fetch project details');
  }
};

export const addProjectDetail = async (projectData) => {
  try {
    const token = getToken();
    const response = await axios.post(BASE_URL, projectData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error adding project detail:', error);
    throw new Error('Failed to add project detail');
  }
};

export const updateProjectDetail = async (id, projectData) => {
  try {
    const token = getToken();
    const response = await axios.put(`${BASE_URL}/${id}`, projectData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating project detail:', error);
    throw new Error(error.response?.data?.message || 'Failed to update project detail');
  }
};

export const deleteProjectDetail = async (id) => {
  try {
    const token = getToken();
    await axios.delete(`${BASE_URL}/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (error) {
    console.error('Error deleting project detail:', error);
    throw new Error('Failed to delete project detail');
  }
};

export const triggerStatusUpdate = async () => {
  try {
    const token = getToken();
    const response = await axios.post(`${BASE_URL}/trigger-status-update`, {}, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error triggering status update:', error);
    throw new Error('Failed to trigger status update');
  }
};

export const handleFileUpload = async (file, projectId) => {
  try {
    const token = getToken();
    const formData = new FormData();
    formData.append('serviceAccountFile', file);
    formData.append('projectId', projectId);

    const response = await axios.post(`${BASE_URL}/upload-service-account`, formData, {
      headers: { 
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      },
    });
    console.log('File upload response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error uploading service account file:', error);
    throw new Error(error.response?.data?.message || 'Failed to upload service account file');
  }
};