// websiteApi.js
import axios from 'axios';

const BASE_URL = `${process.env.REACT_APP_API_URL}/api/websites`;
const getToken = () => localStorage.getItem('accessToken');

export const fetchAgents = async () => {
    try {
      const token = getToken();
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/agents`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch agents');
    }
  };
  
export const fetchWebsites = async () => {
  try {
    const token = getToken();
    const response = await axios.get(BASE_URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch websites');
  }
};

export const addWebsite = async (newWebsite) => {
  try {
    const token = getToken();
    const response = await axios.post(BASE_URL, newWebsite, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      throw new Error('Website URL already exists. Please choose a different URL.');
    } else {
      throw new Error('Failed to add website');
    }
  }
};

export const updateWebsite = async (id, updatedWebsite) => {
  try {
    const token = getToken();
    const response = await axios.put(`${BASE_URL}/${id}`, updatedWebsite, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      throw new Error('Website URL already exists. Please choose a different URL.');
    } else {
      throw new Error('Failed to update website');
    }
  }
};

export const deleteWebsite = async (id) => {
  try {
    const token = getToken();
    await axios.delete(`${BASE_URL}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    throw new Error('Failed to delete website');
  }
};