import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from './templates/Header';
import Footer from './templates/Footer';
import Sidebar from './templates/Sidebar';

import OfflineNotification from './templates/OfflineNotification';
const Layout = ({ children }) => {
  const [pageTitle, setPageTitle] = useState('Dashboard'); // State untuk judul, default 'Dashboard'
  const location = useLocation(); // Gunakan useLocation untuk mendapatkan informasi path dari URL

  // Menggunakan useEffect untuk memperbarui judul ketika path berubah
  useEffect(() => {
    switch (location.pathname) {
      case '/daftaragent':
        setPageTitle('Daftar Agent/Toko Yang Ikut Autoapprove');
        break;
      case '/daftarwebsite':
        setPageTitle('Daftar Website');
        break;
      case '/daftarpegawai':
        setPageTitle('Daftar Pegawai');
        break;
      case '/automember':
        setPageTitle('Daftar Google Cloud yang Sedang digunakan (LIVE)');
        break;
      case '/notifikasi':
        setPageTitle('Notifikasi');
        break;
      case '/daftaruser':
        setPageTitle('Daftar Users');
        break;
        case '/daftarroles':
          setPageTitle('Daftar Roles');
        break;
        case '/daftarfixbug':
          setPageTitle('Daftar Fix Bug');
        break;
        case '/daftarbackupproject':
          setPageTitle('Backup Project GoogleCloud');
        break;
        case '/daftarlistemail':
          setPageTitle('List Email Terpakai dan Belum Digunakan');
        break;
        case '/bugreports':
          setPageTitle('Cara menyelesaikan Error Jobdesk');
        break;
        case '/projectarchive':
          setPageTitle('List Email dan Project Sudah Terbanned');
        break;
      default:
        setPageTitle('');
    }
  }, [location.pathname]); // Efek ini hanya dipanggil jika location.pathname berubah

  return (
    <div id="wrapper">
      <Sidebar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <Header />

          <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-0 text-gray-800">{pageTitle}</h1>
            </div>

            <div className="row">
              <div className="col-lg-12">
                
                  <div className="card-body">
                 
                    <OfflineNotification />
                    {children} {/* Konten dinamis (AutoMember atau halaman lainnya) */}
                 
                  </div>
               
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Layout;