// pegawaiApi.js
import axios from 'axios';

const BASE_URL = `${process.env.REACT_APP_API_URL}/api/pegawai`;
const getToken = () => localStorage.getItem('accessToken');

export const fetchUsers = async () => {
  try {
    const token = getToken();
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.map(user => ({
      ...user,
      full_name: user.full_name,
      role_id: user.role_id  // Ubah dari roles_id menjadi role_id
    }));
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const fetchAvailablePegawai = async () => {
  try {
    const token = getToken();
    const response = await axios.get(`${BASE_URL}/available`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching available pegawai:', error);
    throw error;
  }
};


export const fetchPegawai = async () => {
  try {
    const token = getToken();
    const response = await axios.get(BASE_URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch pegawai');
  }
};

export const fetchPositions = async () => {
  try {
    const token = getToken();
    const response = await axios.get(`${BASE_URL}/positions`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch positions');
  }
};

export const fetchRoles = async () => {
  try {
    const token = getToken();
    const response = await axios.get(`${BASE_URL}/roles`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch roles');
  }
};

export const fetchAgents = async () => {
  try {
    const token = getToken();
    const response = await axios.get(`${BASE_URL}/agents`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch agents');
  }
};

export const fetchShifts = async () => {
  try {
    const token = getToken();
    const response = await axios.get(`${BASE_URL}/shifts`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch shifts');
  }
};



export const addPegawai = async (pegawaiData) => {
  try {
    console.log('Sending pegawai data:', Object.fromEntries(pegawaiData));
    const token = getToken();
    const response = await axios.post(BASE_URL, pegawaiData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Full error object:', error);
    console.error('Error response:', error.response);
    console.error('Error message:', error.message);
    if (error.response && error.response.data) {
      throw new Error(error.response.data.message || 'An error occurred');
    } else {
      throw new Error('Network error or server is not responding');
    }
  }
};

export const updatePegawai = async (id, updatedPegawai) => {
  try {
    const token = getToken();
    const response = await axios.put(`${BASE_URL}/${id}`, updatedPegawai, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data; // Mengembalikan data pegawai yang diperbarui
  } catch (error) {
    if (error.response && error.response.status === 400) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error('Failed to update pegawai');
    }
  }
};

export const deletePegawai = async (id) => {
  try {
    const token = getToken();
    await axios.delete(`${BASE_URL}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    throw new Error('Failed to delete pegawai');
  }
};

export const getPegawaiById = async (id) => {
  try {
    const token = getToken();
    const response = await axios.get(`${BASE_URL}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch pegawai details');
  }
};