import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Login from './components/Login';
import ProtectedRoute from './components/ProtectedRoute';
import Layout from './components/Layout';
import DaftarAgent from './pages/DaftarAgent';
import DaftarPegawai from './pages/DaftarPegawai';
import DaftarWebsite from './pages/DaftarWebsite';
import AutoMember from './pages/AutoMember';
import Unauthorized from './pages/Unauthorized';
import DaftarUser from './pages/DaftarUser';
import DaftarRoles from './pages/DaftarRoles';


import EmployeeView from './pages/EmployeeView';
import BugReports from './pages/BugReports';
import DaftarListEmail from './pages/DaftarListEmail';
import DaftarBackupProject from './pages/DaftarBackupProject';
import ProjectArchiveList from './pages/ProjectArchiveList';
import Notifikasi from './pages/Notifikasi';
import ErrorBoundary from './components/ErrorBoundary';


function App() {
  return (
    <ErrorBoundary>
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="/" element={<Navigate replace to="/login" />} />
          
          {/* Rute yang hanya bisa diakses admin */}
          <Route element={<ProtectedRoute allowedRoles={['admin','pengurus']} />}>
            <Route path="/daftarroles" element={<Layout><DaftarRoles/></Layout>} />
            <Route path="/notifikasi" element={<Layout><Notifikasi /></Layout>} />
            <Route path="/daftaragent" element={<Layout><DaftarAgent /></Layout>} />
            <Route path="/daftarwebsite" element={<Layout><DaftarWebsite /></Layout>} />
            <Route path="/automember" element={<Layout><AutoMember /></Layout>} />
            <Route path="/daftaruser" element={<Layout><DaftarUser /></Layout>} />
            <Route path="/daftarpegawai" element={<Layout><DaftarPegawai /></Layout>} />
            <Route path="/profile" element={<Layout><EmployeeView /></Layout>} />
            <Route path="/bugreports" element={<Layout><BugReports /></Layout>} />
            <Route path="/daftarlistemail" element={<Layout><DaftarListEmail /></Layout>} />
            <Route path="/daftarbackupproject" element={<Layout><DaftarBackupProject /></Layout>} />
            <Route path="/projectarchive" element={<Layout><ProjectArchiveList /></Layout>} />
          </Route>
          
          {/* Rute yang bisa diakses admin dan customer */}
          <Route element={<ProtectedRoute allowedRoles={['bos']} />}>
            <Route path="/notifikasi" element={<Layout></Layout>} />
            <Route path="/daftaragent" element={<Layout><DaftarAgent /></Layout>} />
            <Route path="/daftarwebsite" element={<Layout><DaftarWebsite /></Layout>} />
            <Route path="/automember" element={<Layout><AutoMember /></Layout>} />
            <Route path="/daftaruser" element={<Layout><DaftarUser /></Layout>} />
            <Route path="/daftarpegawai" element={<Layout><DaftarPegawai /></Layout>} />
            <Route path="/profile" element={<Layout><EmployeeView /></Layout>} />
          </Route>

          <Route element={<ProtectedRoute allowedRoles={['wakilbos']} />}>
            <Route path="/notifikasi" element={<Layout></Layout>} />
            <Route path="/daftaragent" element={<Layout><DaftarAgent /></Layout>} />
            <Route path="/daftarwebsite" element={<Layout><DaftarWebsite /></Layout>} />
            <Route path="/automember" element={<Layout><AutoMember /></Layout>} />
            <Route path="/daftaruser" element={<Layout><DaftarUser /></Layout>} />
            <Route path="/daftarpegawai" element={<Layout><DaftarPegawai /></Layout>} />
            <Route path="/profile" element={<Layout><EmployeeView /></Layout>} />
          </Route>

          <Route element={<ProtectedRoute allowedRoles={['pengurus']} />}>
            <Route path="/notifikasi" element={<Layout></Layout>} />
            <Route path="/daftaragent" element={<Layout><DaftarAgent /></Layout>} />
            <Route path="/daftarwebsite" element={<Layout><DaftarWebsite /></Layout>} />
            <Route path="/automember" element={<Layout><AutoMember /></Layout>} />
            <Route path="/daftaruser" element={<Layout><DaftarUser /></Layout>} />
            <Route path="/daftarpegawai" element={<Layout><DaftarPegawai /></Layout>} />
            <Route path="/profile" element={<Layout><EmployeeView /></Layout>} />
          </Route>

          <Route element={<ProtectedRoute allowedRoles={['operator']} />}>
            <Route path="/notifikasi" element={<Layout></Layout>} />
            <Route path="/daftaragent" element={<Layout><DaftarAgent /></Layout>} />
            <Route path="/daftarwebsite" element={<Layout><DaftarWebsite /></Layout>} />
            <Route path="/automember" element={<Layout><AutoMember /></Layout>} />
            <Route path="/daftaruser" element={<Layout><DaftarUser /></Layout>} />
            <Route path="/daftarpegawai" element={<Layout><DaftarPegawai /></Layout>} />
            <Route path="/profile" element={<Layout><EmployeeView /></Layout>} />
          </Route>

          <Route element={<ProtectedRoute allowedRoles={['marketing']} />}>
            <Route path="/notifikasi" element={<Layout></Layout>} />
            <Route path="/daftaragent" element={<Layout><DaftarAgent /></Layout>} />
            <Route path="/daftarwebsite" element={<Layout><DaftarWebsite /></Layout>} />
            <Route path="/automember" element={<Layout><AutoMember /></Layout>} />
            <Route path="/daftaruser" element={<Layout><DaftarUser /></Layout>} />
            <Route path="/daftarpegawai" element={<Layout><DaftarPegawai /></Layout>} />
            <Route path="/profile" element={<Layout><EmployeeView /></Layout>} />
          </Route>

        </Routes>
      </Router>
    </AuthProvider>
    </ErrorBoundary>
  );
}

export default App;