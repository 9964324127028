import axios from 'axios';

const BASE_URL = `${process.env.REACT_APP_API_URL}/api/agents`;

const getToken = () => localStorage.getItem('accessToken');

export const fetchAgents = async () => {
  try {
    const token = getToken();
    const response = await axios.get(BASE_URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch agents');
  }
};

export const addAgent = async (newAgent) => {
  try {
    const token = getToken();
    const response = await axios.post(BASE_URL, newAgent, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      throw new Error('Agent code or name already exists. Please choose a different one.');
    } else {
      throw new Error('Failed to add agent');
    }
  }
};

export const updateAgent = async (id, updatedAgent) => {
  try {
    const token = getToken();
    const response = await axios.put(`${BASE_URL}/${id}`, updatedAgent, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      throw new Error('Agent code or name already exists. Please choose a different one.');
    } else {
      throw new Error('Failed to update agent');
    }
  }
};

export const deleteAgent = async (id) => {
  try {
    const token = getToken();
    await axios.delete(`${BASE_URL}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    throw new Error('Failed to delete agent');
  }
};
