import axios from 'axios';

const BASE_URL = `${process.env.REACT_APP_API_URL}/api/emails`;

const getToken = () => localStorage.getItem('accessToken');

export const fetchEmails = async () => {
  try {
    const token = getToken();
    const response = await axios.get(BASE_URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch emails');
  }
};

export const addEmail = async (newEmail) => {
  try {
    const token = getToken();
    const response = await axios.post(BASE_URL, newEmail, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to add email');
  }
};

export const updateEmail = async (id, updatedEmail) => {
  try {
    const token = getToken();
    const response = await axios.put(`${BASE_URL}/${id}`, updatedEmail, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to update email');
  }
};

export const deleteEmail = async (id) => {
  try {
    const token = getToken();
    await axios.delete(`${BASE_URL}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    throw new Error('Failed to delete email');
  }
};