import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}/api/auth/`; // Sesuaikan dengan URL backend Anda
const api = axios.create({
  baseURL: API_URL,
});

export const login = async (username, password) => {
  const response = await api.post('/login', { username, password });
  return response.data;
};

// Tambahkan fungsi API lainnya di sini