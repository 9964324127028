import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { fetchPegawai, addPegawai, updatePegawai, deletePegawai, fetchPositions, fetchRoles, fetchAgents, fetchShifts, fetchUsers,fetchAvailablePegawai } from '../services/pegawaiApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTrash, faCheckCircle, faTimesCircle, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';

function DaftarPegawai() {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [showModal, setShowModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [availablePegawai, setAvailablePegawai] = useState([]);
const [filteredPositions, setFilteredPositions] = useState([]);
  const [newPegawai, setNewPegawai] = useState({
    pegawai_id: '',
    full_name: '',
    position_id: '',
    roles_id: '',
    hire_date: '',
    salary: '',
    phone_number: '',
    email: '',
    nationality: '',
    agents_id: '',
    shift_id: '',
    profile_picture: ''
  });
  const [editingPegawai, setEditingPegawai] = useState(null);
  const [data, setData] = useState([]);
  const [positions, setPositions] = useState([]);
  const [roles, setRoles] = useState([]);
  const [agents, setAgents] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const itemsPerPage = 10;
  const maxPagesPerSet = 10;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [pegawaiData, positionsData, rolesData, agentsData, shiftsData, availablePegawaiData] = await Promise.all([
          fetchPegawai(),
          fetchPositions(),
          fetchRoles(),
          fetchAgents(),
          fetchShifts(),
          fetchAvailablePegawai()
        ]);
        
        console.log("Fetched positions:", positionsData);
        console.log("Fetched roles:", rolesData);
        console.log("Available pegawai:", availablePegawaiData);
        
        setData(pegawaiData);
        setPositions(positionsData);
        setRoles(rolesData);
        setAgents(agentsData);
        setShifts(shiftsData);
        setAvailablePegawai(availablePegawaiData);
        setError(null);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // Tambahkan useEffect ini di bawah useEffect yang sudah ada
useEffect(() => {
  if (editingPegawai) {
    const filteredPositions = getFilteredPositions(editingPegawai.roles_id);
    setFilteredPositions(filteredPositions);
  }
}, [editingPegawai]);


const getFilteredPositions = (roleId) => {
  console.log("Current roleId:", roleId);
  console.log("All positions:", positions);

  let filtered;
  switch (parseInt(roleId)) {
    case 7: // Untuk bos
      filtered = positions.filter(p => p.name.toLowerCase().includes('owner'));
      break;
    case 5: // Untuk wakilbos
      filtered = positions.filter(p => p.name.toLowerCase().includes('direktur'));
      break;
    case 6 : // Untuk pengurus
      filtered = positions.filter(p => 
        p.name.toLowerCase().includes('supervisor') ||
        p.name.toLowerCase().includes('manager')
      );
      break;
    case 4: // Untuk marketing
      filtered = positions.filter(p => 
        ['it staff', 'seo staff', 'movies staff', 'streamer staff', 'spammer staff'].some(term => 
          p.name.toLowerCase().includes(term)
        )
      );
      break;
    case 3: // Untuk operator
      filtered = positions.filter(p => p.name.toLowerCase().includes('operator'));
      break;
    default:
      filtered = [];
  }

  console.log("Filtered positions:", filtered);
  return filtered;
};

  const filteredData = data.filter((item) => {
    const nameMatch = item.full_name?.toLowerCase().includes(searchTerm.toLowerCase()) || false;
    const emailMatch = item.email?.toLowerCase().includes(searchTerm.toLowerCase()) || false;
    return (nameMatch || emailMatch);
  });

  const pageCount = Math.ceil(filteredData.length / itemsPerPage);

  const getCurrentPageData = () => {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return filteredData.slice(begin, end);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < pageCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const getPaginationButtons = () => {
    const currentPageSet = Math.floor((currentPage - 1) / maxPagesPerSet);
    const startPage = currentPageSet * maxPagesPerSet + 1;
    const endPage = Math.min(startPage + maxPagesPerSet - 1, pageCount);

    const buttons = [];
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <Button
          key={i}
          variant={i === currentPage ? "primary" : "light"}
          onClick={() => handlePageChange(i)}
          className="mr-1"
        >
          {i}
        </Button>
      );
    }

    return buttons;
  };

  const handleShowModal = () => {
    setEditingPegawai(null);
    setNewPegawai({
      pegawai_id: '',
      full_name: '',
      position_id: '',
      roles_id: '',
      hire_date: '',
      salary: '',
      phone_number: '',
      email: '',
      nationality: '',
      agents_id: '',
      shift_id: '',
      profile_picture: ''
    });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setError(null);
  };

// Ganti fungsi handleInputChange yang sudah ada dengan yang ini
const handleInputChange = (e) => {
  const { name, value } = e.target;
  let processedValue = value;

  if (['position_id', 'agents_id', 'shift_id', 'user_id', 'roles_id'].includes(name)) {
    processedValue = parseInt(value, 10);
  }

  if (name === 'user_id') {
    const selectedPegawai = availablePegawai.find(pegawai => pegawai.id === parseInt(value, 10));
    console.log("Selected pegawai:", selectedPegawai);
    
    if (selectedPegawai) {
      const newData = {
        [name]: processedValue,
        full_name: selectedPegawai.full_name,
        roles_id: selectedPegawai.role_id
      };
      console.log("New data:", newData);
      
      const filteredPositions = getFilteredPositions(selectedPegawai.role_id);
      console.log("Filtered positions:", filteredPositions);
      
      setFilteredPositions(filteredPositions);
      
      if (editingPegawai) {
        setEditingPegawai(prev => ({ ...prev, ...newData, position_id: '' }));
      } else {
        setNewPegawai(prev => ({ ...prev, ...newData, position_id: '' }));
      }
    }
  } else {
    if (editingPegawai) {
      setEditingPegawai(prev => ({ ...prev, [name]: processedValue }));
    } else {
      setNewPegawai(prev => ({ ...prev, [name]: processedValue }));
    }
  }
};


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (editingPegawai) {
      setEditingPegawai(prev => ({ ...prev, profile_picture: file }));
    } else {
      setNewPegawai(prev => ({ ...prev, profile_picture: file }));
    }
  };

  const handleAddPegawai = async () => {
    try {
      const requiredFields = ['user_id', 'pegawai_id', 'full_name', 'position_id', 'roles_id', 
                              'hire_date', 'salary', 'phone_number', 'email', 'nationality', 
                              'agents_id', 'shift_id'];
      const missingFields = requiredFields.filter(field => !newPegawai[field]);
      if (missingFields.length > 0) {
        throw new Error(`Missing required fields: ${missingFields.join(', ')}`);
      }
  
      const formData = new FormData();
      Object.keys(newPegawai).forEach(key => {
        if (key === 'profile_picture' && newPegawai[key] instanceof File) {
          formData.append('profile_picture', newPegawai[key]);
        } else {
          formData.append(key, newPegawai[key]);
        }
      });
  
      console.log('Data yang akan dikirim:', Object.fromEntries(formData));
  
      const addedPegawai = await addPegawai(formData);
      setData(prevData => [...prevData, addedPegawai]);
      setAvailablePegawai(prevPegawai => prevPegawai.filter(pegawai => pegawai.id !== addedPegawai.user_id));
      setShowModal(false);
      Swal.fire({
        title: 'Sukses',
        text: 'Pegawai berhasil ditambahkan.',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    } catch (error) {
      console.error('Error adding pegawai:', error);
      Swal.fire({
        title: 'Error',
        text: error.message || 'Terjadi kesalahan saat menambahkan pegawai.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };
  
const handleUpdatePegawai = async () => {
  try {
    const formData = new FormData();
    Object.keys(editingPegawai).forEach(key => {
      if (key === 'profile_picture' && editingPegawai[key] instanceof File) {
        formData.append(key, editingPegawai[key]);
      } else if (key !== 'profile_picture' || (key === 'profile_picture' && typeof editingPegawai[key] === 'string')) {
        formData.append(key, editingPegawai[key]);
      }
    });
    const updatedPegawai = await updatePegawai(editingPegawai.id, formData);
    setData(prevData => prevData.map(pegawai => 
      pegawai.id === updatedPegawai.id ? updatedPegawai : pegawai
    ));
    setShowModal(false);
    setEditingPegawai(null);
    Swal.fire({
      title: 'Sukses',
      text: 'Pegawai berhasil diperbarui.',
      icon: 'success',
      confirmButtonText: 'OK',
    });
  } catch (error) {
    Swal.fire({
      title: 'Error',
      text: error.response?.data?.message || 'Terjadi kesalahan saat memperbarui pegawai.',
      icon: 'error',
      confirmButtonText: 'OK',
    });
  }
};

  const handleDeletePegawai = async (id) => {
    const result = await Swal.fire({
      title: 'Confirmation',
      text: 'Are you sure you want to delete this pegawai?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    });

    if (result.isConfirmed) {
      try {
        await deletePegawai(id);
        setData(data.filter(pegawai => pegawai.id !== id));
        if (getCurrentPageData().length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
        Swal.fire({
          title: 'Success',
          text: 'Pegawai successfully deleted.',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: error.message,
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    }
  };

  const handleEdit = (pegawai) => {
    setEditingPegawai(pegawai);
    setShowModal(true);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleStatusFilter = (e) => {
    setStatusFilter(e.target.value);
    setCurrentPage(1);
  };

  if (loading) return <div className="text-center mt-5"><div className="spinner-border" role="status"><span className="sr-only">Loading...</span></div></div>;
  if (error) return <div className="alert alert-danger m-3" role="alert">{error}</div>;
 
  return (
    <div>
      <div className="card shadow mb-4">
        <div className="card-header py-3 d-flex justify-content-between align-items-center">
          <h6 className="m-0 font-weight-bold text-primary">Daftar Pegawai</h6>
          <div className="d-flex">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="form-control mr-2"
            />
            <Button variant="primary" onClick={handleShowModal}>
              Tambah Pegawai
            </Button>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
              <thead>
                <tr>
                  <th>No</th>
                  <th>ID Pegawai</th>
                  <th>Nama Lengkap</th>
                  <th>Posisi</th>
                  <th>Role</th>
                  <th>Tanggal Mulai</th>
                  <th>Gaji</th>
                  <th>No. Telepon</th>
                  <th>Email</th>
                  <th>Kewarganegaraan</th>
                  <th>Agent</th>
                  <th>Shift</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {getCurrentPageData().map((item, index) => (
                  <tr key={item.id}>
                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                    <td>{item.pegawai_id}</td>
                    <td>{item.full_name}</td>
                    <td>{item.position_name}</td>
                    <td>{item.role_name}</td>
                    <td>{new Date(item.hire_date).toLocaleDateString()}</td>
                    <td>{item.salary}</td>
                    <td>{item.phone_number}</td>
                    <td>{item.email}</td>
                    <td>{item.nationality}</td>
                    <td>{item.agent_name}</td>
                    <td>{item.shift_name}</td>
                    <td>
                      <Button variant="info" size="sm" className="mr-2" onClick={() => handleEdit(item)}>
                        <FontAwesomeIcon icon={faInfoCircle} /> Edit
                      </Button>
                      <Button variant="danger" size="sm" onClick={() => handleDeletePegawai(item.id)}>
                        <FontAwesomeIcon icon={faTrash} /> Hapus
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination">
            <Button
              variant="light"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className="mr-1"
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </Button>
            {getPaginationButtons()}
            <Button
              variant="light"
              onClick={handleNextPage}
              disabled={currentPage === pageCount}
              className="ml-1"
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </Button>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{editingPegawai ? 'Edit Pegawai' : 'Tambah Pegawai'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
          <div className="form-group">
            <label>User</label>
            <select 
              className="form-control" 
              name="user_id" 
              value={editingPegawai ? editingPegawai.user_id : newPegawai.user_id} 
              onChange={handleInputChange}
              required
            >
              <option value="">Pilih Pegawai</option>
              {availablePegawai.map(pegawai => (
                <option key={pegawai.id} value={pegawai.id}>{pegawai.username} - {pegawai.full_name}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Nama Lengkap</label>
            <input 
              type="text" 
              className="form-control" 
              name="full_name" 
              value={editingPegawai ? editingPegawai.full_name : newPegawai.full_name} 
              readOnly 
            />
          </div>
          <div className="form-group">
            <label>Role</label>
            <input 
              type="text" 
              className="form-control" 
              name="roles_id" 
              value={(() => {
                const roleId = editingPegawai ? editingPegawai.roles_id : newPegawai.roles_id;
                const role = roles.find(r => r.id === roleId);
                return role ? role.name : 'Users Belum dipilih';
              })()}
              readOnly 
            />
          </div>
            <div className="form-group">
              <label>ID Pegawai</label>
              <input type="text" className="form-control" name="pegawai_id" value={editingPegawai ? editingPegawai.pegawai_id : newPegawai.pegawai_id} onChange={handleInputChange} />
            </div>
              <div className="form-group">
                <label>Posisi</label>
                <select 
                  className="form-control" 
                  name="position_id" 
                  value={editingPegawai ? editingPegawai.position_id : newPegawai.position_id} 
                  onChange={handleInputChange}
                  disabled={!newPegawai.user_id && !editingPegawai}
                >
                  <option value="">Pilih Posisi</option>
                  {filteredPositions.map(position => (
                    <option key={position.id} value={position.id}>{position.name}</option>
                  ))}
                </select>
              </div>
            <div className="form-group">
              <label>Tanggal Mulai</label>
              <input type="date" className="form-control" name="hire_date" value={editingPegawai ? editingPegawai.hire_date : newPegawai.hire_date} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label>Gaji</label>
              <input type="number" className="form-control" name="salary" value={editingPegawai ? editingPegawai.salary : newPegawai.salary} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label>No. Telepon</label>
              <input type="tel" className="form-control" name="phone_number" value={editingPegawai ? editingPegawai.phone_number : newPegawai.phone_number} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label>Email</label>
              <input type="email" className="form-control" name="email" value={editingPegawai ? editingPegawai.email : newPegawai.email} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label>Kewarganegaraan</label>
              <input type="text" className="form-control" name="nationality" value={editingPegawai ? editingPegawai.nationality : newPegawai.nationality} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label>Agent</label>
              <select className="form-control" name="agents_id" value={editingPegawai ? editingPegawai.agents_id : newPegawai.agents_id} onChange={handleInputChange}>
                <option value="">Pilih Agent</option>
                {agents.map(agent => (
                  <option key={agent.id} value={agent.id}>{agent.name}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Shift</label>
              <select className="form-control" name="shift_id" value={editingPegawai ? editingPegawai.shift_id : newPegawai.shift_id} onChange={handleInputChange}>
                <option value="">Pilih Shift</option>
                {shifts.map(shift => (
                  <option key={shift.id} value={shift.id}>{shift.name}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Foto Profil</label>
              <input type="file" className="form-control-file" onChange={handleFileChange} />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Tutup
          </Button> 
          <Button variant="primary" onClick={editingPegawai ? handleUpdatePegawai : handleAddPegawai}>
            {editingPegawai ? 'Update' : 'Tambah'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DaftarPegawai;