import axios from 'axios';

const BASE_URL = `${process.env.REACT_APP_API_URL}/api/bug-reports`;

const getToken = () => localStorage.getItem('accessToken');

export const fetchBugReports = async () => {
  try {
    const token = getToken();
    const response = await axios.get(BASE_URL, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching bug reports:', error);
    throw new Error('Failed to fetch bug reports');
  }
};

export const addBugReport = async (bugReportData) => {
  try {
    const token = getToken();
    const response = await axios.post(BASE_URL, bugReportData, {
      headers: { 
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error adding bug report:', error);
    throw new Error('Failed to add bug report');
  }
};

export const updateBugReport = async (id, bugReportData) => {
  try {
    const token = getToken();
    const response = await axios.put(`${BASE_URL}/${id}`, bugReportData, {
      headers: { 
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating bug report:', error);
    throw new Error('Failed to update bug report');
  }
};

export const deleteBugReport = async (id) => {
  try {
    const token = getToken();
    await axios.delete(`${BASE_URL}/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (error) {
    console.error('Error deleting bug report:', error);
    throw new Error('Failed to delete bug report');
  }
};