import React, { useState, useEffect } from 'react';
import { Table, Button, Pagination } from 'react-bootstrap';
import { fetchProjectArchives } from '../services/projectArchiveApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';

function ProjectArchiveList() {
  const [archives, setArchives] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 10;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const data = await fetchProjectArchives();
      setArchives(data);
      setError(null);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  const handleViewDetails = (archive) => {
    Swal.fire({
      title: 'Project Archive Details',
      html: `
        <p><strong>Project ID:</strong> ${archive.project_id}</p>
        <p><strong>Name:</strong> ${archive.nama_project}</p>
        <p><strong>Email:</strong> ${archive.email}</p>
        <p><strong>Status:</strong> ${archive.status}</p>
        <p><strong>Archived At:</strong> ${new Date(archive.archived_at).toLocaleString()}</p>
      `,
      icon: 'info'
    });
  };

  const filteredArchives = archives.filter(archive =>
    Object.values(archive).some(value =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const pageCount = Math.ceil(filteredArchives.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredArchives.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="card shadow mb-4">
      <div className="card-header py-3 d-flex justify-content-between align-items-center">
        <h6 className="m-0 font-weight-bold text-primary">Project Archives</h6>
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="form-control w-25"
        />
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <Table bordered hover>
            <thead>
              <tr>
                <th>No</th>
                <th>Project ID</th>
                <th>Email</th>
                <th>Name</th>
                <th>Status</th>
                <th>Archived At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((archive, index) => (
                <tr key={archive.id}>
                  <td>{indexOfFirstItem + index + 1}</td>
                  <td>{archive.project_id}</td>
                  <td>{archive.email}</td>
                  <td>{archive.nama_project}</td>
                  <td>{archive.status}</td>
                  <td>{new Date(archive.archived_at).toLocaleString()}</td>
                  <td>
                    <Button variant="info" size="sm" onClick={() => handleViewDetails(archive)}>
                      <FontAwesomeIcon icon={faEye} /> View
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <div className="card-footer">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, filteredArchives.length)} of {filteredArchives.length} entries
          </div>
          <Pagination>
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </Pagination.Prev>
            {[...Array(pageCount)].map((_, i) => (
              <Pagination.Item
                key={i}
                active={i + 1 === currentPage}
                onClick={() => handlePageChange(i + 1)}
              >
                {i + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === pageCount}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </Pagination.Next>
          </Pagination>
        </div>
      </div>
    </div>
  );
}

export default ProjectArchiveList;