// profileApi.js
import axios from 'axios';


const BASE_URL = `${process.env.REACT_APP_API_URL}/api/auth`;
const getToken = () => localStorage.getItem('accessToken');

export const getProfile = async () => {
  try {
    const token = getToken();
    const response = await axios.get(`${BASE_URL}/profile`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching profile:', error.response?.data || error.message);
    throw new Error('Failed to fetch profile');
  }
};

export const updateProfile = async (updatedProfile) => {
  try {
    const token = getToken();
    const response = await axios.put(`${BASE_URL}/profile`, updatedProfile, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating profile:', error.response?.data || error.message);
    throw new Error('Failed to update profile');
  }
};

export const changePassword = async (passwordData) => {
  try {
    const token = getToken();
    const response = await axios.put(`${BASE_URL}/change-password`, passwordData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error changing password:', error.response?.data || error.message);
    throw new Error('Failed to change password');
  }
};

export const getLoginHistory = async () => {
  try {
    const token = getToken();
    const response = await axios.get(`${BASE_URL}/login-history`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching login history:', error.response?.data || error.message);
    throw new Error('Failed to fetch login history');
  }
};

export const getShiftSchedule = async (month, year) => {
  try {
    const token = getToken();
    const response = await axios.get(`${BASE_URL}/shift-schedule`, {
      params: { month, year },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching shift schedule:', error.response?.data || error.message);
    throw new Error('Failed to fetch shift schedule');
  }
};

export const requestLeave = async (leaveData) => {
  try {
    const token = getToken();
    const response = await axios.post(`${BASE_URL}/request-leave`, leaveData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error requesting leave:', error.response?.data || error.message);
    throw new Error('Failed to request leave');
  }
};