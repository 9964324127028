import React, { useState, useEffect } from 'react';
import { Modal, Button,Dropdown } from 'react-bootstrap';
import { fetchProjectDetails, addProjectDetail, updateProjectDetail, deleteProjectDetail, triggerStatusUpdate, handleFileUpload } from '../services/automemberApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faCheck, faTimes, faBan, faExclamationTriangle, faQuestion, faExclamationCircle, faHourglassHalf, faMinus, faSync, faEdit, faTrash, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import loadingGif from '../assets/loading.gif';

function AutoMember() {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [editingProject, setEditingProject] = useState(null);
  const [newProjectFile, setNewProjectFile] = useState(null);
  const [isUpdatingStatuses, setIsUpdatingStatuses] = useState(false);
const [updateProgress, setUpdateProgress] = useState(0);
  const [newProject, setNewProject] = useState({
    email: '',
    password: '',
    nama_project: '',
    nama_web: '',
    kode_agent: '',
    tanggal_buat: '',
    group_agent: '',
    PIC: '',
    project_id: ''
  });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const itemsPerPage = 20;
  const [selectedFile, setSelectedFile] = useState(null);
  const [statusFilter, setStatusFilter] = useState('all');
const [filteredData, setFilteredData] = useState([]);

  const handleFileSelect = (event) => {
    if (editingProject) {
      setSelectedFile(event.target.files[0]);
    } else {
      setNewProjectFile(event.target.files[0]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let filtered = data.filter(item =>
      Object.values(item).some(value =>
        value !== null && value !== undefined && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  
    if (statusFilter !== 'all') {
      filtered = filtered.filter(item => {
        if (statusFilter === 'active') {
          return item.status === 'active' || item.status === 'live';
        } else if (statusFilter === 'error') {
          return item.status === 'error';
        }
        return true;
      });
    }
  
    setFilteredData(filtered);
    setCurrentPage(1);
  }, [data, searchTerm, statusFilter]);

  const fetchData = async () => {
    try {
      const projectDetails = await fetchProjectDetails();
      setData(projectDetails);
      setError(null);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  const handleStatusFilterChange = (status) => {
    setStatusFilter(status);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setEditingProject(null);
    setNewProject({
      email: '',
      password: '',
      nama_project: '',
      nama_web: '',
      kode_agent: '',
      tanggal_buat: '',
      group_agent: '',
      PIC: '',
      project_id: ''
    });
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingProject) {
      setEditingProject(prev => ({ ...prev, [name]: value }));
    } else {
      setNewProject(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleAddProject = async () => {
    try {
      console.log("Data yang akan dikirim:", newProject);
      const formData = new FormData();
      for (const key in newProject) {
        formData.append(key, newProject[key]);
      }
      if (newProjectFile) {
        formData.append('serviceAccountFile', newProjectFile);
      }
      await addProjectDetail(formData);
      fetchData();
      handleCloseModal();
      Swal.fire({
        title: 'Success',
        text: 'Project detail added successfully',
        icon: 'success',
        confirmButtonText: 'OK'
      });
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };

  const handleUpdateProject = async () => {
    try {
      if (!editingProject.email || !editingProject.nama_project || !editingProject.nama_web) {
        throw new Error('Email, nama project, dan nama web harus diisi');
      }

      const formattedDate = new Date(editingProject.tanggal_buat).toISOString().split('T')[0];
      const updatedProject = {
        ...editingProject,
        tanggal_buat: formattedDate
      };

      await updateProjectDetail(updatedProject.id, updatedProject);

      if (selectedFile) {
        await handleFileUpload(selectedFile, updatedProject.id);
      }

      fetchData();
      handleCloseModal();
      Swal.fire({
        title: 'Success',
        text: 'Project detail updated successfully',
        icon: 'success',
        confirmButtonText: 'OK'
      });
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };

  const handleDeleteProject = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });

      if (result.isConfirmed) {
        await deleteProjectDetail(id);
        fetchData();
        Swal.fire(
          'Deleted!',
          'Project detail has been deleted.',
          'success'
        );
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };

  const handleTriggerStatusUpdate = async () => {
    try {
      setIsUpdatingStatuses(true);
      await triggerStatusUpdate();
      await fetchData();
      setIsUpdatingStatuses(false);
      Swal.fire({
        title: 'Success',
        text: 'Status update triggered successfully',
        icon: 'success',
        confirmButtonText: 'OK'
      });
    } catch (error) {
      setIsUpdatingStatuses(false);
      Swal.fire({
        title: 'Error',
        text: error.message || 'Failed to trigger status update',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };

  const handleFileUploadWrapper = async (event, id) => {
    try {
      const file = event.target.files[0];
      console.log(`Uploading file for project with ID: ${id}`);
      const result = await handleFileUpload(file, id);
      console.log('Upload result:', result);

      setData(prevData => prevData.map(project =>
        project.id === id ? { ...project, service_account_file: result.fileName } : project
      ));

      Swal.fire({
        title: 'Success',
        text: 'Service account file uploaded successfully',
        icon: 'success',
        confirmButtonText: 'OK'
      });
     
      fetchData();
    } catch (error) {
      console.error('File upload error:', error);
      Swal.fire({
        title: 'Error',
        text: error.message || 'Failed to upload service account file',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'active':
      case 'live':
        return <FontAwesomeIcon icon={faCheck} className="text-success" title="Active" />;
      case 'inactive':
        return <FontAwesomeIcon icon={faTimes} className="text-danger" title="Inactive" />;
      case 'banned':
        return <FontAwesomeIcon icon={faBan} className="text-warning" title="Banned" />;
      case 'invalid':
        return <FontAwesomeIcon icon={faExclamationTriangle} className="text-danger" title="Invalid" />;
      case 'unknown':
        return <FontAwesomeIcon icon={faQuestion} className="text-secondary" title="Unknown" />;
      case 'error':
        return <FontAwesomeIcon icon={faExclamationCircle} className="text-danger" title="Error" />;
      case 'pending':
        return <FontAwesomeIcon icon={faHourglassHalf} className="text-info" title="Pending" />;
      default:
        return <FontAwesomeIcon icon={faMinus} className="text-secondary" title="Not Set" />;
    }
  };
 


  const pageCount = Math.ceil(filteredData.length / itemsPerPage);

  const getCurrentPageData = () => {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return filteredData.slice(begin, end);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      {isUpdatingStatuses && (
        <div 
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999
          }}
        >
          <div
            style={{
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <img src={loadingGif} alt="Loading" style={{ width: '250px', height: '250px' }} />
            <p style={{ marginTop: '10px', color: 'black' }}>Updating Statuses...</p>
          </div>
        </div>
      )}
      <div className="card shadow mb-4">
        <div className="card-header py-3 d-flex justify-content-between align-items-center">
          <h6 className="m-0 font-weight-bold text-primary">Project Details</h6>
          <div className="d-flex">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="form-control mr-2"
            />
            <Button variant="primary" onClick={handleShowModal} className="mr-2">
              Add Project
            </Button>
            <Button 
  variant="primary" 
  onClick={handleTriggerStatusUpdate} 
  className="mr-2"
  disabled={isUpdatingStatuses}
>
  <FontAwesomeIcon icon={faSync} /> Update Statuses
</Button>
            <Dropdown className="mr-2">
            <Dropdown.Toggle variant="primary" id="dropdown-status-filter">
              <FontAwesomeIcon icon={faFilter} /> Status: {statusFilter === 'all' ? 'All' : statusFilter}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleStatusFilterChange('all')}>All</Dropdown.Item>
              <Dropdown.Item onClick={() => handleStatusFilterChange('active')}>Active</Dropdown.Item>
              <Dropdown.Item onClick={() => handleStatusFilterChange('error')}>Error</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Email</th>
                  <th>Password</th>
                  <th>Nama Project</th>
                  <th>Nama Web</th>
                  <th>Kode Agent</th>
                  <th>Tanggal Buat</th>
                  <th>Group Agent</th>
                  <th>Status</th>
                  <th>PIC</th>
                  <th>Project ID</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {getCurrentPageData().map((item, index) => (
                  <tr key={item.id}>
                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                    <td>{item.email}</td>
                    <td>{item.password}</td>
                    <td>{item.nama_project}</td>
                    <td>{item.nama_web}</td>
                    <td>{item.kode_agent}</td>
                    <td>{new Date(item.tanggal_buat).toLocaleDateString()}</td>
                    <td>{item.group_agent}</td>
                    <td>{getStatusIcon(item.status)}</td>
                    <td>{item.PIC}</td>
                    <td>{item.project_id}</td>
                    <td>
                      <Button variant="info" size="sm" className="mr-2" onClick={() => { setEditingProject(item); setShowModal(true); }}>
                        <FontAwesomeIcon icon={faEdit} /> Edit
                      </Button>
                      <Button variant="danger" size="sm" onClick={() => handleDeleteProject(item.id)}>
                        <FontAwesomeIcon icon={faTrash} /> Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="card-footer">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              Showing {(currentPage - 1) * itemsPerPage + 1} to {Math.min(currentPage * itemsPerPage, filteredData.length)} of {filteredData.length} entries
            </div>
            <nav>
              <ul className="pagination">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                  <Button className="page-link" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </Button>
                </li>
                {[...Array(pageCount)].map((_, i) => (
                  <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                    <Button className="page-link" onClick={() => handlePageChange(i + 1)}>{i + 1}</Button>
                  </li>
                ))}
                <li className={`page-item ${currentPage === pageCount ? 'disabled' : ''}`}>
                  <Button className="page-link" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === pageCount}>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </Button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{editingProject ? 'Edit Project' : 'Add Project'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label>Email</label>
              <input type="email" className="form-control" name="email" value={editingProject ? editingProject.email : newProject.email} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label>Password</label>
              <input type="password" className="form-control" name="password" value={editingProject ? editingProject.password : newProject.password} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label>Nama Project</label>
              <input type="text" className="form-control" name="nama_project" value={editingProject ? editingProject.nama_project : newProject.nama_project} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label>Nama Web</label>
              <input type="text" className="form-control" name="nama_web" value={editingProject ? editingProject.nama_web : newProject.nama_web} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label>Kode Agent</label>
              <input type="text" className="form-control" name="kode_agent" value={editingProject ? editingProject.kode_agent : newProject.kode_agent} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label>Keys Json service Account</label>
              <input
                type="file"
                onChange={handleFileSelect}
                accept=".json"
              />
            </div>
            <div className="form-group">
              <label>Tanggal Buat</label>
              <input
                type="date"
                className="form-control"
                name="tanggal_buat"
                value={editingProject ? editingProject.tanggal_buat.split('T')[0] : newProject.tanggal_buat}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label>Group Agent</label>
              <input type="text" className="form-control" name="group_agent" value={editingProject ? editingProject.group_agent : newProject.group_agent} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label>PIC</label>
              <input type="text" className="form-control" name="PIC" value={editingProject ? editingProject.PIC : newProject.PIC} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label>Project ID (Google Cloud)</label>
              <input type="text" className="form-control" name="project_id" value={editingProject ? editingProject.project_id : newProject.project_id} onChange={handleInputChange} />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={editingProject ? handleUpdateProject : handleAddProject}>
            {editingProject ? 'Update' : 'Add'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AutoMember;