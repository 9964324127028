import React, { useState, useEffect } from 'react';
import { getProfile } from '../services/profileApi';
import 'bootstrap/dist/css/bootstrap.min.css';

function EmployeeView() {
  const [employeeData, setEmployeeData] = useState(null);

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const data = await getProfile();
        setEmployeeData(data);
      } catch (error) {
        console.error('Error fetching employee data:', error);
      }
    };

    fetchEmployeeData();
  }, []);

  if (!employeeData) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="container mt-5">
      <div className="card shadow-lg">
        <div className="card-header bg-primary text-white">
          <h2>Employee Information</h2>
        </div>
        <div className="card-body">
          <h5 className="card-title">{employeeData.full_name}</h5>
          <p className="card-text"><strong>Employee ID:</strong> {employeeData.pegawai_id}</p>
          <p className="card-text"><strong>Position:</strong> {employeeData.position_name}</p>
          <p className="card-text"><strong>Role:</strong> {employeeData.role_name}</p>
          <p className="card-text"><strong>Hire Date:</strong> {employeeData.hire_date}</p>
          <p className="card-text"><strong>Email:</strong> {employeeData.email}</p>
          <p className="card-text"><strong>Phone:</strong> {employeeData.phone_number}</p>
          <p className="card-text"><strong>Nationality:</strong> {employeeData.nationality}</p>
          <p className="card-text"><strong>Agent:</strong> {employeeData.agent_name}</p>
          <p className="card-text"><strong>Shift:</strong> {employeeData.shift_name}</p>
        </div>
      </div>
    </div>
  );
}

export default EmployeeView;
